import React, { Fragment, useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADSelectCountry } from '../components/select';
import { ADButton } from '../components/ui/button';
import { ADCard, ADCardBody, ADCardFooter, ADCardHeader } from '../components/ui/card';
import { SearchIcon, AddIcon, NewIcon } from '../components/ui/icons';
import { ADInput } from '../components/ui/input';
import { ADLabel } from '../components/ui/label';
import { ADLoading } from '../components/ui/loading';
import { ADNoRecords } from '../components/ui/noRecords';
import { ADPageActions, ADPageHeader, ADPageTitle } from '../components/ui/page';
import { ADTable, ADTableBody, ADTableHead, ADTableTD, ADTableTH, ADTableTR } from '../components/ui/table';
import { useContact2 } from '../hooks/useContact';
import { useOrganizationID } from '../hooks/useOrganizationID';
import { constAPISuccess, constRecordNotFound, delay, getMessage, processError, constAdd } from '../utils/helper';

export function ListContactPage2(props) {
	let organizationID = useOrganizationID();
	let navigate = useNavigate()

	const [state, stateList, { create, list, handleInputChange }] = useContact2()

	useEffect(() => {
		list()
	}, [])
	
	const handleNewContact = (event) => {
		navigate(`/${organizationID}/contact/create`)
	}

	const ListContact = () => {
		return (
			<Fragment>				
				{!stateList.loading && stateList.data && stateList.data.length === 0 &&
					<ADNoRecords />
				}
				{stateList.data && stateList.data.length > 0 &&
					<ADTable>
						<ADTableHead>
							<ADTableTR>
								<ADTableTH>ID</ADTableTH>
								<ADTableTH>Name</ADTableTH>
								<ADTableTH>Email</ADTableTH>
								<ADTableTH>Mobile</ADTableTH>
							</ADTableTR>
						</ADTableHead>
						<ADTableBody>
							{listContactItem}
						</ADTableBody>
					</ADTable>

				}
				{stateList.loading &&
					<ADLoading />
				}
			</Fragment>
		)
	}

	const listContactItem = stateList.data.map((data, index) => {
		return (
			<Fragment>
				<ADTableTR>
					<ADTableTD>{data.id}</ADTableTD>
					<ADTableTD>{data.name}</ADTableTD>
					<ADTableTD>{data.email}</ADTableTD>
					<ADTableTD>{data.mobileNumber}</ADTableTD>
				</ADTableTR>				
			</Fragment>
		)
	});

	return (
		<>
			<div className="container-xl">
				<ADPageHeader>
					<ADPageTitle>Contacts</ADPageTitle>
					<ADPageActions>
						<div className="col-auto">
							<ADInput
								id="search"
								name="search"
								required={true}
								placeholder="Search Name, Email, Mobile"
								marginBottom="mb-0"
								action={
									<ADButton
										key="search"
										isProcessing={false}
										style="light"
										type="submit"
										prefixIcon={<SearchIcon />}
									/>
								}
							/>
						</div>
						<div className="col-auto">
							<ADButton value="New Contact" prefixIcon={<NewIcon />} css="mb-0" onClick={handleNewContact} />
						</div>
					</ADPageActions>
				</ADPageHeader>

				<div className="row">
					<div className="col-12">
						<ADCard>
							<ADCardBody>
								<ListContact />
							</ADCardBody>
						</ADCard>
					</div>
				</div>
			</div>
		</>
	)
}

export function CreateContactPage2(props) {

	let organizationID = useOrganizationID();
	let navigate = useNavigate()

	const [state, stateList, { create, list, handleInputChange }] = useContact2()
	const [error, setError] = useState({})

	const handleFormSubmit = async (event) => {
		setError({})
		await create()
		if (state.status === constAPISuccess) {
			toast.success(getMessage(constAdd, 'Contact'));
			navigate(`/${organizationID}/contact`)
		} else {
			console.log(">>on Error Handling")
			console.log(state)
			if (state.error) {
				const [isRecordNotFound, err] = processError(state.error)
				if (isRecordNotFound) {
					toast.error(getMessage(constRecordNotFound))
					navigate(`/${organizationID}/contact`)
				} else {
					console.log(err)
					setError(err)
				}
			}
		}
	}

	return (
		<>
			<div className="container-xl">
				<ADPageHeader>
					<ADPageTitle>New Contact</ADPageTitle>
				</ADPageHeader>

				<div className="row">
					<div className="col-12 col-lg-8">
						<ADCard>
							<ADCardBody>
								<ADInput
									id="name"
									name="name"
									type="text"
									label="Name"
									required={true}
									placeholder="Ex. Harry"
									maxLength={50}
									error={error.name}
									onChange={handleInputChange}
								/>

								<ADInput
									id="email"
									name="email"
									type="email"
									label="Email"
									placeholder="yourname@gmail.com"
									maxLength={50}
									error={error.email}
									onChange={handleInputChange}
								/>
								<div className="row">
									<div className="col-6">
										<ADSelectCountry
											id="mobileCountryCode"
											name="mobileCountryCode"
											label={"Country Code"}
											showCountryCode={true}
											onChange={(e) => { handleInputChange(e, "mobileCountryCode") }}
											error={error.mobileCountryCode}
										/>
									</div>
									<div className="col-6">
										<ADInput
											id="mobileNumber"
											name="mobileNumber"
											type="number"
											label="Mobile Number"
											maxLength={11}
											error={error.mobileNumber}
											onChange={handleInputChange}
										/>
									</div>
								</div>
							</ADCardBody>
							<ADCardFooter>
								<ADButton
									style="light"
									isProcessing={false}
									value="Cancel"
									size="lg"
								/>
								<ADButton
									isProcessing={state.loading}
									value="Save"
									size="lg"
									onClick={handleFormSubmit}
								/>
							</ADCardFooter>
						</ADCard>
					</div>
				</div>
			</div>
		</>
	)
}
