import React, { Fragment } from "react"
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useOrganizationID } from "../../hooks/useOrganizationID";
import { isNullOrEmpty } from "../../utils/helper";

/**
 * 
 * @param {object} children - Children elements
 * @param {string} to - to URL
 * @param {string} css - css class names
 * @param {boolean} hasOrganizationID - Will prefix the organization id to the url
 * @returns 
 */
export function ADLink({ children, to, css="", hasOrganizationID = true, ...props }) {
	let organizationID = useOrganizationID();
	let urlTo =  hasOrganizationID ? `/${organizationID}${to}` : `${to}`
	let resolved = useResolvedPath(urlTo);
	let match = useMatch({ path: resolved.pathname, end: true });
	let style = !isNullOrEmpty(match) ? css + " active" : css

	return (
		<div>
			<Link
				className={`${style}`}
				to={urlTo}
				{...props}
			>
				{children}
			</Link>
		</div>
	);
}