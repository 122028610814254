import React, { useEffect, useState } from 'react'
const DashboardPage = (props) => {
	const [state, setState] = useState(0)
	let i = 1
	useEffect(() => {	  
		console.log(">>Welcome")		
		console.log(i=i+1)
	}, [])
	
	return (
		<>
			<div className="container-xl">
				<h1 className="app-page-title">Dashboard</h1>				
			</div>
		</>
	)
}

export default DashboardPage;