import React, { Fragment, useState } from 'react'
import { useOrganizationID } from '../hooks/useOrganizationID';

import { ConstDocumentTypeInventorySummary, ConstDocumentTypeInventorySummaryCSV, ConstKVPNameFirstName, getDocumentUrl, getKVP } from '../utils/helper';
import { HomeIcon, PersonIcon, SettingsIcon, AboutIcon, ItemIcon, PurchaseIcon, SalesIcon, InventoryIcon, HelpIcon } from './ui/icons';
import { ADLink } from './ui/link';

const ADHeader = (props) => {

	// ===== Responsive Sidepanel ======
	const [sidePanel, setSidePanel] = useState("sidepanel-visible")
	// const [sidePanelToggler, setSidePanelToggler] = useState("sidepanel-hidden")

	window.addEventListener('load', function () {
		responsiveSidePanel();
	});

	window.addEventListener('resize', function () {
		responsiveSidePanel();
	});


	function responsiveSidePanel() {
		let w = window.innerWidth;
		if (w >= 1200) {
			// if larger 
			setSidePanel("sidepanel-visible")

		} else {
			// if smaller
			setSidePanel("sidepanel-hidden")
		}
	};

	const handleSidePanelTogglerClick = (e) => {
		if (sidePanel === "sidepanel-visible") {
			setSidePanel("sidepanel-hidden")
		} else {
			setSidePanel("sidepanel-visible")
		}
	}

	const handleSidePanelCloseClick = (e) => {
		e.preventDefault()
		handleSidePanelTogglerClick(e)
	}

	const handleSidePanelDropClick = (e) => {
		e.preventDefault()
		handleSidePanelTogglerClick(e)
	}

	return (
		<Fragment>
			<header className="app-header fixed-top">
				<div className="app-header-inner">
					<div className="container-fluid py-2">
						<div className="app-header-content">
							<div className="row justify-content-between align-items-center">

								<div className="col-auto">
									<a id="sidepanel-toggler" className="sidepanel-toggler d-inline-block d-xl-none" href="#" onClick={handleSidePanelTogglerClick}>
										<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
											role="img">
											<title>Menu</title>
											{/* <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
												stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path> */}
											<path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10"
												strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"></path>
										</svg>
									</a>
								</div>
								{/* col */}
								{/* <div className="search-mobile-trigger d-sm-none col">
									<i className="search-mobile-trigger-icon fas fa-search"></i>
								</div> */}
								{/* //col */}
								{/* <div className="app-search-box col">
									<form className="app-search-form">
										<input type="text" placeholder="Search..." name="search"
											className="form-control search-input" />
										<button type="submit" className="btn search-btn btn-primary" value="Search"><i
											className="fas fa-search"></i></button>
									</form>
								</div> */}
								{/* //app-search-box */}
								<div className="app-utilities col-auto">

									{/* <!--//app-utility-item--> */}
									{/* <div className="app-utility-item">
										<a href="settings.html" title="Settings">
											<SettingsIcon />
										</a>
									</div> */}
									{/* <!--//app-utility-item--> */}

									<div className="app-utility-item app-user-dropdown dropdown">
										<a className="dropdown-toggle" id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#"
											role="button" aria-expanded="false">
											<PersonIcon />
										</a>
										<ul className="dropdown-menu" aria-labelledby="user-dropdown-toggle">

											{/* <li><a className="dropdown-item" href="account.html">Account</a></li>
											<li><a className="dropdown-item" href="settings.html">Settings</a></li> */}
											<li className="dropdown-item">Welcome {getKVP(ConstKVPNameFirstName)} </li>
											<li>
												<hr className="dropdown-divider" />
											</li>
											<li><a to="/logout" className="dropdown-item">Log Out</a></li>
										</ul>
									</div>
									{/* <!--//app-user-dropdown--> */}
								</div>
								{/* <!--//app-utilities--> */}
							</div>
							{/* <!--//row--> */}
						</div>
						{/* <!--//app-header-content--> */}
					</div>
					{/* <!--//container-fluid--> */}
				</div>
				{/* <!--//app-header-inner--> */}
				<div id="app-sidepanel" className={`app-sidepanel ${sidePanel}`}>
					<div id="sidepanel-drop" className="sidepanel-drop" onClick={handleSidePanelDropClick}></div>
					<div className="sidepanel-inner d-flex flex-column">
						<a href="#" id="sidepanel-close" className="sidepanel-close d-xl-none" onClick={handleSidePanelCloseClick}>&times;</a>
						<div className="app-branding">
							<a className="app-logo" href="index.html">
								{/* <img className="logo-icon mr-2" src="/assets/images/logo.svg"
									alt="logo" /> */}
								<h4>VHelp</h4>
							</a>

						</div>
						{/* <!--//app-branding--> */}

						<nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
							<ul className="app-menu list-unstyled accordion" id="menu-accordion">
								{/* <li className="nav-item">
									<ADLink to="/1" css="nav-link"  hasOrganizationID={false}>
										<span className="nav-icon">
											<HomeIcon />
										</span>
										<span className="nav-link-text">Dashboard</span>
									</ADLink>
								</li> */}

								{/* Contacts */}
								{/* <li className="nav-item">
									<ADLink to="/screen" css="nav-link">
										<span className="nav-icon">
											<PersonIcon />
										</span>
										<span className="nav-link-text">All Screen</span>
									</ADLink>
								</li> */}

								{/* Jobs */}
								<li className="nav-item">
									<ADLink to="/job" css="nav-link">
										<span className="nav-icon">
											<PersonIcon />
										</span>
										<span className="nav-link-text">Jobs</span>
									</ADLink>
								</li>
								{/* <li className="nav-item">
									<ADLink to="/contact/create" css="nav-link">
										<span className="nav-icon">
											<PersonIcon />
										</span>
										<span className="nav-link-text">Contacts</span>
									</ADLink>
								</li> */}


								
								{/* <!--//nav-item--> */}
								{/* <li className="nav-item has-submenu">
									<ADLink to="/settings" css="nav-link submenu-toggle" data-bs-toggle="collapse" data-bs-target="#submenu-1"
										aria-expanded="false" aria-controls="submenu-1">
										<span className="nav-icon">
											<SettingsIcon />
										</span>
										<span className="nav-link-text">Settings</span>
										<span className="submenu-arrow">
											<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down"
												fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd"
													d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
											</svg>
										</span>
										</ADLink>
									<div id="submenu-1" className="collapse submenu submenu-1" data-parent="#menu-accordion">
										<ul className="submenu-list list-unstyled">
											<li className="submenu-item"><ADLink to="/user" css="submenu-link"
											>Users</ADLink></li>
										</ul>
									</div>
								</li> */}

								{/* Help */}
								{/* <li className="nav-item">
									<a href="https://www.youtube.com/playlist?list=PLlvfPaEuQ2Cr49xatcZdoQ3QwenF4OM69" className="nav-link" target="_blank">
										<span className="nav-icon">
											<HelpIcon />
										</span>
										<span className="nav-link-text">Help Videos</span>
									</a>
								</li> */}
							</ul>
							{/* <!--//app-menu--> */}
						</nav>
						{/* <!--//app-nav--> */}
						{/* <div className="app-sidepanel-footer">
							<nav className="app-nav app-nav-footer">
								<ul className="app-menu footer-menu list-unstyled">
									<li className="nav-item">
										<ADLink to="/about"
											css="nav-link">
											<span className="nav-icon">
												<AboutIcon />
											</span>
											<span className="nav-link-text">About</span>
										</ADLink>
									</li>
								</ul>
							</nav>
						</div> */}
						{/* <!--//app-sidepanel-footer--> */}
					</div>
					{/* <!--//sidepanel-inner--> */}
				</div>
				{/* <!--//app-sidepanel--> */}
			</header>
		</Fragment>
	)
}
export default ADHeader;

