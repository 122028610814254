import React, { Fragment, useReducer } from 'react'
import { CreateContactAPI, ListContactAPI, UpdateContactAPI } from '../api/contactAPI'
import { constAPIError, constAPISuccess, delay } from '../utils/helper'

export function useContact2() {
	
	const actionTypes = {
		onChange: 'onChange',
		loading: 'loading',
		success: 'success',
		error: 'error',
		list: 'list'
	}

	const contactReducer = (state, action) => {
		switch (action.type) {
			case actionTypes.onChange: {
				return { ...state, [action.name]: action.value, status: "", error: "", loading: false}
			}
			case actionTypes.success: {
				return { ...state, status: constAPISuccess, error: "", loading: false}
			}
			case actionTypes.error: {
				console.log(">>Reducer ")
				console.log(action.error)
				return { ...state, status: constAPIError, error: action.error, loading: false}
			}
			case actionTypes.list: {
				// return { ...state, data: action.payload, Loading:false }
				return { ...state, data: [...state.data, ...action.data] , status: constAPISuccess, error: "", loading: false }
			}
			case actionTypes.loading: {
				return { ...state, status: "", error: "", loading: true}
			}
			default: {
				throw new Error(`Unhandled type: ${action.type}`)
			}
		}
	}

	const initialState = { status: "", data: { email: "", mobileCountryCode: "", mobileNumber: "", name: "", source: ""}, error: "", loading: false }
	const initialStateList = { status: "", data:[], error: "", loading: false }
	const [state, dispatch] = useReducer(contactReducer, initialState)
	const [stateList, dispatchList] = useReducer(contactReducer, initialStateList)

	const handleInputChange = (event, name = "") => {
		//For Select dropdown
		if (event.value) {
			dispatch({
				type: actionTypes.onChange,
				name: name, value: event.value
			})
		}
		else if (event.target.type == "checkbox") {
			dispatch({
				type: actionTypes.onChange,
				name: event.target.name, value: event.target.checked ? 1 : -1
			})
		} else {
			dispatch({
				type: actionTypes.onChange,
				name: event.target.name, value: event.target.value
			})
		}
	};

	const create = async () => {
		dispatch({ type: actionTypes.loading })
		const dataObject = { email: state.data.email, mobileCountryCode: state.data.mobileCountryCode, mobileNumber: state.data.mobileNumber, name: state.data.name }
		await CreateContactAPI(dataObject).then((data) => {
			console.log(">> Response from server")
			console.log(data)
			//on success
			if (data && data && data.status === constAPISuccess) {
				dispatch({
					type: actionTypes.success,
					data: { ID: data.id }
				})
			} else { //on error
				dispatch({
					type: actionTypes.error,
					error: data.data
				})
			}
		})
	}

	const update = (id) => {
		dispatch({ type: actionTypes.loading })
		const dataObject = { email: state.data.email, mobileCountryCode: state.data.mobileCountryCode, mobileNumber: state.data.mobileNumber, name: state.data.name }
		UpdateContactAPI(id, dataObject).then((data) => {
			dispatch({
				type: actionTypes.success,
				data: { ID: data.id }
			})
		})
	}

	const list = (page = 1) => {
		
		dispatchList({ type: actionTypes.loading })		
		delay(function () {
			ListContactAPI(page).then((data) => {
				var obj = []
				data.data.forEach(e => {
					obj.push({ id: e.id, name: e.name, email: e.email, mobileCountryCode: e.mobileCountryCode, mobileNumber: e.mobileNumber })
				});
				dispatchList({
					type: actionTypes.list,
					data: obj.map(e => { return e }),
				})

			})
		},  5000)
	}

	return [ state, stateList, { create, update, list, handleInputChange }]
}