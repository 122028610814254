import React from 'react'
import { ADLabel } from './label'
import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/material_blue.css";

/**
 * Date control with stack styling.
 * @param props
 * @param  {string} props.id
 * @param  {string} props.name
 * @param  {string} props.label - Optional
 * @param  {boolean} props.required - default to false
 * @param  {object} props.options
 * @param  {string} props.css - css class names
 * @param  {string} props.value
 * @param  {object} props.onChange - function to be triggered
 * @param  {object} props.error - Error message object
 * @param  {object} props.description - Description to be shown
 */
 export function ADDate ({ id, name, label, required=false, options, onChange, value, css="", error, description}) {
	// const { label, placeholder, , ...attr } = props
	return (
		<React.Fragment>
			<div className="mb-3">
				{label &&
					<ADLabel htmlFor={id}  css="mb-1">
						{ label } { !required && <span className="text-danger">- optional</span>}
					</ADLabel>
				}
				<Flatpickr
					className={`form-control ${css}`}
					id={id}
					name={name}
					options={options}
					data-enable-time={true}
					onChange={onChange}
					value={value}
				/>

				{error &&
					<small className="form-text text-danger">{error.message}</small>
				}
				{description &&
					<small className="form-text text-muted">{description}</small>
				}
			</div>
		</React.Fragment>
	)
}