import React, { Fragment, useState, useEffect } from 'react';
import { ADButton } from './components/ui/button'
import { ADCard, ADCardBody, ADCardFooter, ADCardHeader, ADCardTitle } from './components/ui/card'
import { DownArrowIcon, DownShortArrowIcon, ExportIcon, FilterIcon, PersonIcon, SearchIcon, SortIcon, ThreeDotsHorizontalIcon, ThreeDotsVeriticalIcon, YugaOneLoading } from './components/ui/icons';
import { ADLabel } from './components/ui/label';
import { ADDate } from './components/ui/date'
import { ADInput } from './components/ui/input';
import { ADInputArea } from './components/ui/inputArea';
import { ADLoading } from './components/ui/loading';
import { ADNoRecords } from './components/ui/noRecords'
import { ADSwitch } from './components/ui/switch';
import { ADAlert } from './components/ui/alert';
import { ADModal, ADModalBody, ADModalFooter, ADModalHeader } from './components/ui/modal';

import { useUserDemo } from './hooks/useUserDemo'
import ADHeader from './components/header';

function AllScreens() {

	const initialState = { SellingPrice: 0, Description: "", Cricket: false, Football: false }
	const [object, setObject] = useState(initialState);

	var err = { _Global_: { Message: "There is an error" } }

	const handleInputChange1 = (event) => {
		const { name, value } = event.target;
		if (event.target.type === "checkbox") {
			setObject({ ...object, [name]: event.target.checked ? true : false });
		} else {
			setObject({ ...object, [name]: value });
		}
	};

	// Modal
	const [canShowModal, setCanShowModal] = useState(false)
	const onModalClose = () => {
		console.log("Modal - Index Close()");
		setCanShowModal(false);
	}

	const handleShowModal = () => {
		setCanShowModal(true)
	}

	// Using reducers
	const [state, stateList, { create, list, handleInputChange }] = useUserDemo()

	useEffect(() => {
		console.log(">>> USEEFFECT")
		console.log(stateList)
		// if (stateList.data) {
		// 	stateList.data.map((data, index) => {
		// 		console.log(data.Email)
		// 	})
		// }
	}, [])

	const ListUserDemo = () => {

		return (
			<Fragment>
				{stateList.Data && stateList.Data.length == 0 &&
					<ADNoRecords />
				}
				{stateList.Data && stateList.Data.length > 0 &&
					<div className="table-responsive">
						<table className="table app-table-hover mb-0 text-left">
							<thead>
								<tr>
									<th className="cell">Avatar</th>
									<th className="cell">Name</th>
									<th className="cell">Email</th>
								</tr>
							</thead>
							<tbody>
								{/* <ListUserDemoItem /> */}
								{listUserDemoItem}
								{/* {
							stateList.map((data, index) => (
								<tr>
									<td className="cell">{data.Avatar}</td>
									<td className="cell">{data.FirstName} - {data.LastName}</td>
									<td className="cell"></td>
								</tr>
							))
						} */}
							</tbody>
						</table>
					</div>
				}
			</Fragment>
		)
	}

	const ListUserDemoItem1 = ({ stateList }) => {

		return (
			<React.Fragment>
				<tr>
					<td className="cell">{stateList.Avatar}</td>
					<td className="cell">{stateList.FirstName} - {stateList.LastName}</td>
					<td className="cell"></td>
				</tr>
			</React.Fragment>
		)
	}

	const listUserDemoItem = stateList.Data.map((data, index) => {
		return (
			<React.Fragment>
				<tr>
					<td className="cell">{data.Avatar}</td>
					<td className="cell">{data.FirstName} - {data.LastName}</td>
					<td className="cell">{data.Avatar}</td>
				</tr>
			</React.Fragment>
		)
	});

	const ListUserDemoItem = () => {
		const listUserDemoItem = stateList.map((data, index) => {
			return (
				<React.Fragment>
					<tr>
						<td className="cell">{data.Avatar}</td>
						<td className="cell">{data.FirstName} - {data.LastName}</td>
						<td className="cell"></td>
					</tr>
				</React.Fragment>
			)
		});

		return (
			{ listUserDemoItem }

		)
	}

	return (
		<Fragment>
			<div className="app-wrapper1">
				<div className="app-content1 pt-5 p-md-3 p-lg-4">

					<div className="container-xl">

						{/* Page Tite and actions */}
						<div className="row g-3 mb-3 align-items-center justify-content-between">
							<div className="col-auto">
								<h1 className="app-page-title mb-0">All Screen</h1>
							</div>
							<div className="col-auto">
								<div className="page-utilities">
									<div className="row g-2 justify-content-start justify-content-md-end align-items-center">
										<div className="col-auto">
											<div className="docs-search-form row gx-1 align-items-center">
												<div className="col-auto">
													<ADInput
														id="search"
														name="search"
														required={true}
														placeholder="Search Name, SKU, Barcode"
														marginBottom="mb-0"
														action={
															<ADButton
																key="search"
																isProcessing={false}
																style="light"
																type="submit"
																prefixIcon={<SearchIcon />}
															/>
														}
														onChange={handleInputChange} />
												</div>
												<div className="col-auto">
													<ADButton
														prefixIcon={<FilterIcon />}
														isProcessing={false}
														value="Filter"
														style="light"
														onClick={handleShowModal}
													/>
													<ADButton
														prefixIcon={<SortIcon />}
														isProcessing={false}
														value="Sort"
														style="light"
														onClick={handleShowModal}
													/>
													<ADButton
														prefixIcon={<ExportIcon />}
														isProcessing={false}
														value="Export"
														style="light"
														onClick={handleShowModal}
													/>
												</div>
											</div>
										</div>
										<div className="col-auto">
											<div className="dropdown">
												<button className="btn btn-primary dropdown-toggle  text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path></svg> New Item</button>
												<ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
													<li><a className="dropdown-item" href="#">Simple Item</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-auto">
							<nav className="orders-table-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-1">
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<button className="nav-link active border-top-0 border-end-0 border-start-0" id="nav-summary-tab" data-bs-toggle="tab" data-bs-target="#nav-summary" type="button" role="tab" aria-controls="nav-summary" aria-selected="true">Summary</button>
									<button className="nav-link  border-top-0 border-end-0 border-start-0" id="nav-receives-tab" data-bs-toggle="tab" data-bs-target="#nav-receives" type="button" role="tab" aria-controls="nav-receives" aria-selected="false">Receive(s)</button>
									<button className="nav-link  border-top-0 border-end-0 border-start-0" id="nav-returns-tab" data-bs-toggle="tab" data-bs-target="#nav-returns" type="button" role="tab" aria-controls="nav-returns" aria-selected="false">Return(s)</button>
								</div>
							</nav>

							<div className="tab-content" id="nav-tabContent">
								{/* Tab 1 */}
								<div className="tab-pane fade show active" id="nav-summary" role="tabpanel" aria-labelledby="nav-summary-tab">
									<div className="row">
										<div className="col-12">
											<ADCard>
												<ADCardBody>
													<span class="badge bg-success rounded-pill d-inline">Success</span>
													<span class="badge bg-primary rounded-pill d-inline">Primary</span>
													<span class="badge bg-secondary rounded-pill d-inline">Secondary</span>
													<span class="badge bg-danger rounded-pill d-inline">Danger</span>
													<span class="badge bg-warning rounded-pill d-inline">Warning</span>
													<span class="badge bg-info rounded-pill d-inline">Info</span>
													<span class="badge bg-light rounded-pill d-inline">Light</span>
													<span class="badge bg-dark rounded-pill d-inline">Dark</span>
													<hr />
													<span class="badge badge-success rounded-pill d-inline">Success</span>
													<span class="badge badge-primary rounded-pill d-inline">Primary</span>
													<span class="badge badge-secondary rounded-pill d-inline">Secondary</span>
													<span class="badge badge-danger rounded-pill d-inline">Danger</span>
													<span class="badge badge-warning rounded-pill d-inline">Warning</span>
													<span class="badge badge-info rounded-pill d-inline">Info</span>
													<span class="badge badge-light rounded-pill d-inline">Light</span>
													<span class="badge badge-dark rounded-pill d-inline">Dark</span>
												</ADCardBody>
											</ADCard>
										</div>
									</div>									</div>
								{/* Tab 2 */}
								<div className="tab-pane fade" id="nav-receives" role="tabpanel" aria-labelledby="nav-receives-tab">
									<div className="row">
										<div className="col-12">
											<ADCard>
												<ADCardBody>
													{2}
												</ADCardBody>
											</ADCard>
										</div>
									</div>
								</div>
								{/* Tab 3 */}
								<div className="tab-pane fade" id="nav-returns" role="tabpanel" aria-labelledby="nav-returns-tab">
									<div className="row">
										<div className="col-12">
											<ADCard>
												<ADCardBody>
													{3}
												</ADCardBody>
											</ADCard>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Card */}
						<div className="row">
							<div className="col-12">
								<ADCard>
									<ADCardHeader
										title="Card Title"
										actions={
											<div className="app-card-actions">
												<div className="dropdown">
													<div className="dropdown-toggle no-toggle-arrow" data-bs-toggle="dropdown" aria-expanded="false">
														<ThreeDotsHorizontalIcon />
													</div>
													<ul className="dropdown-menu dropdown-menu-right">
														<li><a className="dropdown-item" href="#">View</a></li>
														<li><a className="dropdown-item" href="#">Edit</a></li>
														<li><a className="dropdown-item" href="#">Download</a></li>
														<li><hr className="dropdown-divider" /></li>
														<li><a className="dropdown-item" href="#">Delete</a></li>
													</ul>
												</div>
											</div>
										}
										description="This is the card description"
									/>
									<ADCardBody>
										<ADLabel>Control</ADLabel>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
									</ADCardBody>
									<ADCardFooter>
										<ADButton
											isProcessing={false}
											value="Primary"
											size="lg"
										/>
										<ADButton
											isProcessing={false}
											value="Light Button Show Modal"
											style="light"
											onClick={handleShowModal}
										/>
									</ADCardFooter>

								</ADCard>
							</div>
						</div>

						{/* Settings */}
						<div className="row">
							<div className="col-12 col-md-4">
								<h3 className="app-page-title">General</h3>
								<div>Settings section intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a href="help.html">Learn more</a></div>
							</div>
							<div className="col-12 col-md-8">
								<ADCard>									
									<ADCardBody>
										<ADLabel>Control</ADLabel>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
									</ADCardBody>
									<ADCardFooter>
										<ADButton
											isProcessing={false}
											value="Primary"
											size="lg"
										/>
										<ADButton
											isProcessing={false}
											value="Light Button Show Modal"
											style="light"
											onClick={handleShowModal}
										/>
									</ADCardFooter>

								</ADCard>
							</div>
						</div>

						<hr className='mb-2' />

						{/* Date */}
						<div className="row">
							<div className="col-12">
								{/* <ADDate
									id="CurrentDate"
									name="CurrentDate"
									description="Pick a current date"
									label="Current Date"
									required={true}
								/> */}
							</div>
						</div>

						{/* Input Text */}
						<div className="row">
							<div className="col-6">
								<ADInput
									id="email"
									name="email"
									type="email"
									label="Email"
									required={true}
									placeholder="yourname@gmail.com"
									maxLength={20}
									description="Email to communicate"
									onChange={handleInputChange} />
							</div>
							<div className="col-6">
								<ADInput
									id="SellingPrice"
									name="SellingPrice"
									type="number"
									label="Price"
									required={true}
									maxLength={20}
									allowNegative={false}
									description="Selling Price"
									css="text-end"
									prefixLabel="$"
									value={object.SellingPrice}
									onChange={handleInputChange} />
							</div>
						</div>

						{/* Textarea */}
						<div className="row">
							<div className="col-12">
								<ADInputArea
									id="Description"
									name="Description"
									rows={5}
									label="Description"
									required={true}
									maxLength={20}
									allowNegative={false}
									description="Product Description"
									value={object.Description}
									onChange={handleInputChange} />
							</div>
						</div>

						{/* Loader */}
						<div className="row">
							<div className="col-12">
								<ADLoading />
								<YugaOneLoading />
							</div>
						</div>

						{/* No Recrds */}
						<div className="row">
							<div className="col-12">
								<ADNoRecords />
							</div>
						</div>

						{/* Switch */}
						<div className="row">
							<div className="col-12">
								<ADSwitch id="Cricket" name="Cricket" checked={object.Cricket} label="Cricket" onChange={handleInputChange} />
								<ADSwitch id="Football" name="Football" checked={object.Football} label="Football" onChange={handleInputChange} />
							</div>
						</div>

						{/* Alert */}
						<div className="row">
							<div className="col-12">
								<ADAlert error={err} />
							</div>
						</div>

					</div>

					{/* Modal dialog */}
					{canShowModal === true &&
						// <Customer canShow={canShow} onModalClose={onModalClose} />
						<ADModal canShow={canShowModal} >
							<ADModalHeader title="Stock Adjustment" onModalClose={onModalClose} />
							<ADModalBody>
								<h1> Great</h1> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
								<h2> oh</h2>
							</ADModalBody>
							<ADModalFooter>
								<ADButton value="Submit" type="submit" />
							</ADModalFooter>
						</ADModal>
					}

					{/* Reducers */}
					<form>
						<div className="container-xl">

							{/* Card */}
							<div className="row">
								<div className="col-12">
									<ADCard>
										<ADCardHeader
											title="Reducers"
											icon={<PersonIcon />}
										/>
										<ADCardBody>
											<ADLabel>{state.Data.ID} - {state.Data.CreatedAt} </ADLabel>
											<div className="icon-group-right">

												<ADInput
													id="Name"
													name="Name"
													type="text"
													label="Name"
													required={true}
													maxLength={20}
													value={state.Data.Name}
													onChange={handleInputChange} />

												<div className="icon-right">
													Rs.
												</div>
											</div>

											<ADInput
												id="Job"
												name="Job"
												type="text"
												label="Job"
												required={true}
												maxLength={20}
												value={state.Data.Job}
												onChange={handleInputChange} />
										</ADCardBody>
										<ADCardFooter>
											<ADButton
												onClick={create}
												isProcessing={state.Loading}
												value="Create"
											/>
										</ADCardFooter>
									</ADCard>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<ADCard>
										<ADCardHeader
											title="Reducer - List"
											icon={<PersonIcon />}
										/>
										<ADCardBody>
											<ListUserDemo />
										</ADCardBody>
										<ADCardFooter>
											<div className='d-flex justify-content-center'>
												<ADButton
													onClick={list}
													style="light rounded-pill"
													isProcessing={stateList.Loading}
													value="Load More"
													suffixIcon={<DownShortArrowIcon />}
												/>
											</div>
										</ADCardFooter>
									</ADCard>
								</div>
							</div>

						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
}

export default AllScreens;
