import React from 'react'

/**
 * Page Header for title and actions
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADPageHeader({ css = "", ...props }) {
	return (
		<React.Fragment>
			{/* Page Tite and actions */}
			<div className={`row g-3 mb-3 align-items-center justify-content-between  ${css}`}>
				{props.children}
			</div>
		</React.Fragment>
	)
}

/**
 * Page Title
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADPageTitle({ css = "", ...props }) {
	return (
		<React.Fragment>
			<div className={`col-auto ${css}`}>
				<h1 className="app-page-title mb-0">{props.children}</h1>
			</div>
		</React.Fragment>
	)
}

/**
* Page Actions like search, Add new button etc., Every item inside should be wrapped with appropriate columns
* @param props
* @param  {string} props.css - css class name
*/
export function ADPageActions({ css = "", ...props }) {
	return (
		<React.Fragment>
			<div className="col-auto">
				<div className="row g-2 justify-content-start justify-content-md-end align-items-center">
					{/* <div className="col-auto">
						<div className="docs-search-form row gx-1 align-items-center"> */}
							{props.children}
						{/* </div>
					</div> */}
				</div>
			</div>
		</React.Fragment>
	)
}
