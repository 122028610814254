import React, { Fragment } from "react"
import { NoRecordsIcon } from '../ui/icons'

export function ADNoRecords() {
  return (
    <Fragment>
      <div className="text-center bg-white text-muted pt-5 pb-5">
        <NoRecordsIcon />
        <p className="pt-2">No record(s) found</p>
      </div>
    </Fragment>
  )
}


