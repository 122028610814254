import React, { Fragment } from "react"
import { LoadingIcon } from "./icons"

export function ADLoading() {
	return (
		<Fragment>
			<div className="row">
				<div className="col-12 text-center text-muted">
					<LoadingIcon height="50px" width="50px" fillColor="#9fa7b5" />
					<p className="pt-2">Loading...</p>
				</div>
			</div>
		</Fragment>
	)
}


