import React, { Fragment } from 'react'

/**
 * Modal Header component
 * @param props
 * @param {string} title
 * @param {string} onModalClose
 */
export function ADModalHeader ({title, onModalClose, ...props}) {
	const onModalCloseInner = () => {
		onModalClose();
	}
	return (
		<Fragment>				
			<div className="modal-header">
				{title &&
					<h4 className="app-card-title">{title}</h4>
				}
				<button type="button" className="btn-close " data-dismiss="modal" aria-label="Close" onClick={onModalCloseInner} />
				{props.children}
			</div>
			
		</Fragment>
	)
}

/**
 * Modal Body component
 * @param props
 */
 export function ADModalBody (props) {
	return (
		<Fragment>				
			<div className="modal-body">
				{/* <div className="container-fluid"> */}
					{props.children}
				{/* </div> */}
			</div>
			
		</Fragment>
	)
 }

 /**
 * Modal Footer component
 * @param props
 */
export function ADModalFooter (props) {
	return (
		<Fragment>				
			<div className="modal-footer">
				{props.children}
			</div>
		</Fragment>
	)
}

/**
 * Modal component
 * @param props
 * @param {boolean} canShow
 */
export function ADModal ({ canShow, ...props }) { 
	const canShowModal = () => {
		if (canShow === true) {
			return "app-modal modal show";
		} else {
			return "app-modal modal";
		}
	}

	const canShowModalStyle = () => {
		if (canShow === true) {
			return { display: 'block' };
		} else {
			return { display: 'none' } ;
		}
	}

	return (			
		<div className={canShowModal()} role="dialog" style={canShowModalStyle()} >
			<div className="modal-dialog modal-dialog-slideout" role="document">	
				<div className="modal-content">					
						{props.children}					
				</div>	
			</div>
		</div>
	)
}
