import React from 'react';

/**
 * Loading Icon
 * @param props
 * @param  {string} props.width
 * @param  {string} props.height
 * @param  {string} props.fillColor 
 */

export function ThreeDotsVeriticalIcon() {
	return (
		<svg height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" /></svg>
	)
}

export function ThreeDotsHorizontalIcon() {
	return (
		<svg height="25" viewBox="0 0 48 48" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none" /><path d="M12 20c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm24 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-12 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" /></svg>
	)
}

export function YugaOneLoading() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 90 91"
			height={88}
			width={88}
			style={{
				background: "0 0",
				shapeRendering: "auto",
			}}
		>
			<g
				className="ldl-scale"
				style={{
					transformOrigin: "50% 50%",
					transform: "rotate(0deg) scale(.8,.8)",
				}}
			>
				<g
					className="ldl-ani"
					style={{
						transformOrigin: "45px 45.5px",
						transform: "scale(.91)",
						animation:
							"1.17647s linear 0s infinite normal forwards running breath-bf0ef261-1c30-4380-b6ea-603a8e8e24ea",
					}}
				>
					<g className="ldl-layer">
						<g className="ldl-ani">
							<g
								className="ldl-layer"
								fillRule="evenodd"
								fill="none"
								strokeWidth={1}
								stroke="none"
								id="yo--logo"
							>
								<g className="ldl-ani">
									<g className="ldl-layer" id="Artboard">
										<g className="ldl-ani" transform="translate(-469 -136)">
											<g id="yo-logo">
												<g className="ldl-layer">
													<g className="ldl-ani" transform="translate(469 136)">
														<rect
															rx={45}
															height={91}
															width={90}
															y={0}
															x={0}
															fill="#222D39"
															id="rectangle"
															style={{
																fill: "#222D39",
															}}
														/>
													</g>
												</g>
												<g className="ldl-layer">
													<g className="ldl-ani" transform="translate(469 136)">
														<g id="circle">
															<g className="ldl-layer">
																<g
																	className="ldl-ani"
																	transform="translate(14 15)"
																>
																	<path
																		transform="rotate(105 30.619 30.619)"
																		strokeLinejoin="round"
																		strokeLinecap="round"
																		strokeWidth={5}
																		stroke="#FFF"
																		id="Path"
																		d="M30.619 5.619a24.883 24.883 0 0 0-12.982 3.63 25.128 25.128 0 0 0-8.38 8.374A24.883 24.883 0 0 0 5.62 30.62c0 13.807 11.193 25 25 25s25-11.193 25-25c0-4.896-1.407-9.463-3.84-13.318"
																		style={{
																			stroke: "#fff",
																		}}
																	/>
																</g>
															</g>
															<g className="ldl-layer">
																<g
																	className="ldl-ani"
																	transform="translate(14 15)"
																>
																	<circle
																		r={3.5}
																		cy={46.854}
																		cx={48.529}
																		transform="rotate(135 48.529 46.854)"
																		fill="#FFF"
																		id="Oval"
																		style={{
																			fill: "#fff",
																		}}
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
												<g className="ldl-layer">
													<g className="ldl-ani" transform="translate(469 136)">
														<g
															className="ldl-layer"
															fillRule="nonzero"
															fill="#FFF"
															id="y"
														>
															<path
																d="M7.45 22.015v-7.668L1.443 5.195C.917 4.37.545 3.727.327 3.265.11 2.803 0 2.402 0 2.061c0-.56.22-1.044.663-1.45C1.105.202 1.65 0 2.299 0c.682 0 1.2.19 1.552.569.352.38.88 1.135 1.585 2.267l4.598 7.339 4.647-7.339.705-1.137c.19-.308.4-.597.63-.866.228-.27.483-.476.763-.619.28-.143.62-.214 1.023-.214.626 0 1.15.2 1.569.602.42.401.629.866.629 1.393 0 .43-.106.858-.319 1.287-.212.428-.576 1.022-1.09 1.78l-6.158 9.285v7.668c0 1-.235 1.748-.705 2.243-.47.495-1.068.742-1.795.742-.738 0-1.337-.245-1.795-.734-.459-.49-.688-1.24-.688-2.25Z"
																style={{
																	fill: "#fff",
																}}
																transform="translate(35.468 33.468)"
																className="ldl-ani"
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<style id="breath-bf0ef261-1c30-4380-b6ea-603a8e8e24ea">
				{
					"@keyframes breath-bf0ef261-1c30-4380-b6ea-603a8e8e24ea{0%{animation-timing-function:cubic-bezier(.9647,.2413,-.0705,.7911);transform:scale(.9099999999999999)}51%{animation-timing-function:cubic-bezier(.9226,.2631,-.0308,.7628);transform:scale(1.02994)}to{transform:scale(.9099999999999999)}}"
				}
			</style>
		</svg>
	)
}

export function LoadingIcon({ width = "23px", height = "23px", fillColor = "#fff" }) {
	return (
		<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width={width} height={height} viewBox="0 0 50 50" style={{ enableBackground: "new 0 0 50 50" }}>
			<path fill={fillColor} d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
				<animateTransform attributeType="xml"
					attributeName="transform"
					type="rotate"
					from="0 25 25"
					to="360 25 25"
					dur="1s"
					repeatCount="indefinite" />
			</path>
		</svg>
	)
}

export function PersonIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
			<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
		</svg>
	)
}

export function HelpIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
			<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
			<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
		</svg>
	)
}

// export function ContactIcon() {
// 	return (
// 		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
// 			<path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
// 		</svg>
// 	)
// }

export function HomeIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
			<path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
		</svg>
	)
}

export function NewIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
			<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
		</svg>
	)
}

export function DownArrowIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
			<path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
		</svg>
	)
}
export function DownShortArrowIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
			/>
		</svg>
	)
}


export function SettingsIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
			<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
			<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
		</svg>
	)
}

export function BellIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
			<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
		</svg>
	)
}

export function SearchIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
			<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
		</svg>
	)
}

export function AboutIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-person" viewBox="0 0 16 16">
			<path d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
			<path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
		</svg>
	)
}

export function FilterIcon() {
	return (
		// <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
		// 	<path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
		// </svg>
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
			<path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
		</svg>
	)
}

export function SortIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-sort-down-alt" viewBox="0 0 16 16">
			<path d="M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z" />
		</svg>
	)
}

export function ExportIcon() {
	return (
		<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="currentColor" >
			<path d="M16 2v7h-2v-5h-12v16h12v-5h2v7h-16v-20h16zm2 9v-4l6 5-6 5v-4h-10v-2h10z" />
		</svg>
	)
}

export function NoRecordsIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#9fa7b5" className="bi bi-archive" viewBox="0 0 16 16">
			<path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
		</svg>
	)
}

export function InvalidURLIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#9fa7b5" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" /></svg>
	)
}

export function ItemIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-basket3" viewBox="0 0 16 16">
			<path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h1.717L5.07 1.243a.5.5 0 0 1 .686-.172zM3.394 15l-1.48-6h-.97l1.525 6.426a.75.75 0 0 0 .729.574h9.606a.75.75 0 0 0 .73-.574L15.056 9h-.972l-1.479 6h-9.21z" />
		</svg>
	)
}

export function PurchaseIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bag" viewBox="0 0 16 16">
			<path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
		</svg>
	)
}

export function SalesIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart2" viewBox="0 0 16 16">
			<path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
		</svg>
	)
}

export function InventoryIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart2" viewBox="0 0 16 16">
			<path fillRule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
			<path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
		</svg>
	)
}

export function DeleteIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
			<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
			<path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
		</svg>
	)
}

export function CancelIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
			<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
			<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
		</svg>
	)
}

export function AddIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
			<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
			<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
		</svg>
	)
}

export function PrintIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
			<path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
			<path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
		</svg>
	)
}

export function Man1Icon() {
	return (
		<svg
			width="45" height="45"
			viewBox="0 0 33 33"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<path
					id="a"
					d="M32 16c0 8.836-7.163 16-16 16S0 24.836 0 16C0 7.163 7.163 0 16 0s16 7.163 16 16"
				/>
			</defs>
			<g transform="translate(.766 .916)" fill="none" fillRule="evenodd">
				<mask id="b" fill="#fff">
					<use xlinkHref="#a" />
				</mask>
				<use fill="#C6DDFF" xlinkHref="#a" />
				<path
					d="M12.808 2.381s2.248-3.04 6.23-1.012c4.366 2.225 2.006 7.02 2.006 7.02l-9.382-1.776 1.146-4.232"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M10.932 8.413s-2.454-4.819 1.877-6.032l.778 2.585-2.655 3.447"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M13.013 10.638a1.743 1.743 0 1 1-3.486.098 1.743 1.743 0 0 1 3.486-.098"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M10.323 10.53a.505.505 0 0 1 .192-.034c.112 0 .265.03.444.15.178.12.384.334.59.71a.098.098 0 0 0 .17-.094c-.217-.396-.44-.636-.651-.778a.98.98 0 0 0-.553-.183c-.165 0-.268.05-.277.054a.097.097 0 0 0 .085.175"
					fill="#CB6D63"
					mask="url(#b)"
				/>
				<path
					d="M19.378 10.687a1.743 1.743 0 1 0 3.487 0 1.743 1.743 0 0 0-3.487 0"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M22.144 10.325c-.01-.004-.104-.046-.256-.046-.145 0-.343.04-.554.187s-.435.395-.646.808a.098.098 0 1 0 .173.089c.201-.392.406-.614.585-.737a.77.77 0 0 1 .442-.152c.058 0 .104.008.134.016l.034.01.007.002a.098.098 0 0 0 .081-.177"
					fill="#CB6D63"
					mask="url(#b)"
				/>
				<path
					d="M18.432 14.704s-.983.694-1.879.865c-.895.171-1.766-.349-1.777-.355l-1.046-.602-.429 5.739.116.095a4.154 4.154 0 0 0 5.244.036l.225-.18-.454-5.598"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M13.302 14.009s.977 3.198 3.225 3.162c1.848-.029 2.146-3.162 2.146-3.162h-5.37"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M20.903 7.652a21.721 21.721 0 0 1-.538 4.881c-.27 1.183-1.31 2.013-2.4 2.683l-.2.122c-1.153.71-2.107.734-3.286.062-1.189-.678-2.294-1.575-2.556-2.842-.476-2.304-.54-4.858-.54-4.858s-.67-5.33 4.465-5.26c5.135.07 5.055 5.212 5.055 5.212"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M14.344 9.215a.415.415 0 1 1-.83 0 .415.415 0 0 1 .83 0M18.421 9.215a.415.415 0 1 1-.83 0 .415.415 0 0 1 .83 0"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M15.492 10.302v.666a.098.098 0 0 0 .097.097h.54a.097.097 0 1 0 0-.194h-.442v-.569a.098.098 0 0 0-.195 0"
					fill="#CB6D63"
					mask="url(#b)"
				/>
				<path
					d="m12.381 7.46 2.715-.246s-.243-.703-1.341-.59c-1.123.118-1.374.835-1.374.835M19.378 7.46l-2.715-.246s.243-.703 1.34-.59c1.124.118 1.375.835 1.375.835"
					fill="#000105"
					mask="url(#b)"
				/>
				<path
					d="M14.661 11.917h2.678s-.126.82-1.379.82c-1.253 0-1.299-.82-1.299-.82"
					fill="#FFFFFE"
					mask="url(#b)"
				/>
				<path
					d="M11.558 10.222s.201-6.53.117-6.715c0 0-2.315 2.139-.117 6.715M20.71 10.176l-.348-7.09s2.227 2.212.348 7.09"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M13.707 2.929s-1.593.431-1.539 1.995c.055 1.564-1.003 3.101-1.003 3.101l-.483-1.563s-.373-2.912 3.025-3.533"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M13.305 3.058s1.192-.677 2.377.812c1.01 1.271 3.677 2.58 5.256 1.335 0 0-1.006-3.6-4.93-3.526-3.922.073-2.703 1.379-2.703 1.379"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M29.538 32.45H2.541c.492-2.043.999-3.86 1.5-5.275.741-2.088 1.808-3.31 2.691-4.007.88-.699 1.576-.874 1.576-.874l1.874-.784 11.85.01 1.738.774s2.785.701 4.267 4.881c.501 1.415 1.008 3.232 1.5 5.275"
					fill="#E46E85"
					mask="url(#b)"
				/>
				<path
					d="m16.251 19.013 5.784 2.507s-5.943 5.37-11.852-.012l6.068-2.495"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M9.973 21.686c.014.016.548.645 1.578 1.275a8.637 8.637 0 0 0 4.543 1.258c1.984 0 3.52-.625 4.557-1.251 1.036-.627 1.578-1.253 1.591-1.268l-.415-.358-.003.004c-.05.055-.58.64-1.55 1.207a8.205 8.205 0 0 1-4.18 1.118 8.086 8.086 0 0 1-4.257-1.178 7.391 7.391 0 0 1-1.089-.807 5.355 5.355 0 0 1-.337-.333l-.015-.017-.003-.004-.42.354"
					fill="#FFFFFE"
					mask="url(#b)"
				/>
			</g>
		</svg>
	)
}

export function Man2Icon() {
	return (
		<svg
			viewBox="0 0 33 33"
			width="45" height="45"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<path
					id="a"
					d="M16 32C7.164 32 0 24.837 0 16S7.164 0 16 0c8.837 0 16 7.163 16 16 0 8.836-7.163 16-16 16z"
				/>
			</defs>
			<g transform="translate(.378 .251)" fill="none" fillRule="evenodd">
				<mask id="b" fill="#fff">
					<use xlinkHref="#a" />
				</mask>
				<use fill="#C6DDFF" xlinkHref="#a" />
				<path
					d="M31.689 32.85H.818c1.423-6.39 5.243-7.666 5.243-7.666l7.364-3.444 2.79-.025h.076l2.793.025 7.363 3.444s3.818 1.276 5.241 7.666"
					fill="#FFFFFE"
					mask="url(#b)"
				/>
				<path
					d="M17.94 3.385s2.57-1.145 3.48 1.02c.877 2.084-2.605 4.07-2.605 4.07l-.875-5.09"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M13.135 12.025a1.743 1.743 0 1 1-3.485.1 1.743 1.743 0 0 1 3.485-.1"
					fill="#CD7764"
					mask="url(#b)"
				/>
				<path
					d="M10.449 11.925a.505.505 0 0 1 .188-.033c.111 0 .262.028.44.148.177.119.381.332.587.707a.106.106 0 1 0 .185-.102c-.217-.396-.442-.638-.654-.78a.993.993 0 0 0-.558-.185.682.682 0 0 0-.28.055.106.106 0 0 0 .092.19"
					fill="#AD5344"
					mask="url(#b)"
				/>
				<path
					d="M19.5 12.074a1.744 1.744 0 1 0 3.487 0 1.744 1.744 0 0 0-3.487 0"
					fill="#CD7764"
					mask="url(#b)"
				/>
				<path
					d="M22.27 11.705c-.01-.004-.106-.047-.26-.047a.977.977 0 0 0-.558.188c-.212.148-.437.398-.649.812a.106.106 0 0 0 .188.096c.2-.39.405-.61.582-.733a.764.764 0 0 1 .438-.151.54.54 0 0 1 .132.016l.033.01.007.002v-.002.002-.002.002a.106.106 0 0 0 .087-.193"
					fill="#AD5344"
					mask="url(#b)"
				/>
				<path
					d="M18.555 16.092s-.983.694-1.879.865-1.766-.349-1.777-.355L13.852 16l-.428 5.739.116.095a4.155 4.155 0 0 0 5.244.036l.225-.18-.454-5.598"
					fill="#CD7764"
					mask="url(#b)"
				/>
				<path
					d="M13.424 15.397s.977 3.198 3.225 3.162c1.848-.03 2.146-3.162 2.146-3.162h-5.37"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M21.026 9.04c.01 1.97-.263 3.672-.538 4.88-.269 1.184-1.04 2.013-2.13 2.684l-.199.122a3.797 3.797 0 0 1-3.828.061c-1.189-.678-2.023-1.575-2.285-2.841-.476-2.304-.54-4.859-.54-4.859s-.67-5.33 4.465-5.26c5.135.071 5.055 5.212 5.055 5.212"
					fill="#CD7764"
					mask="url(#b)"
				/>
				<path
					d="M14.466 10.603a.416.416 0 1 1-.831 0 .416.416 0 0 1 .831 0M18.543 10.603a.416.416 0 1 1-.831 0 .416.416 0 0 1 .831 0"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M15.607 11.21v1.146c0 .028.011.055.03.075.02.02.048.03.076.03h.74a.106.106 0 1 0 0-.21h-.634v-1.04a.106.106 0 0 0-.212 0"
					fill="#AD5344"
					mask="url(#b)"
				/>
				<path
					d="m12.503 8.847 2.715-.245s-.243-.703-1.341-.59c-1.123.118-1.374.835-1.374.835M19.5 8.847l-2.714-.245s.242-.703 1.34-.59c1.123.118 1.374.835 1.374.835"
					fill="#000105"
					mask="url(#b)"
				/>
				<path
					d="M14.783 13.305h2.678s-.126.82-1.379.82c-1.253 0-1.299-.82-1.299-.82"
					fill="#FFFFFE"
					mask="url(#b)"
				/>
				<path
					d="M11.68 11.61s.76-5.766.388-6.715c0 0-2.586 2.139-.388 6.715M20.832 11.564s-.856-5.506-.618-7.09c0 0 2.496 2.212.618 7.09"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="m20.035 4.081-2.367-.358-.209-.41-1.25.19-1.249-.19-.208.41-2.368.358-.315 1.267.077 1.46s.375-1.656 1.68-1.326c.307.078.675.206 1.106.293.402.088.785.127 1.147.134h.261a5.84 5.84 0 0 0 1.147-.134c.43-.087.807-.189 1.106-.293 1.21-.421 1.655 1.25 1.655 1.25l.102-1.384-.315-1.267"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M19.988 5.522s-1.54 1.374-3.512.318c0 0-5.019 3.092-6.435-.273C8.86 2.76 13.76-1.047 18.82 3.244l1.168 2.278"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="m13.424 21.738-1.645.756s-.018 2.758 4.452 2.615c4.584-.147 4.556-2.573 4.556-2.573l-1.779-.847-5.584.049"
					fill="#CD7764"
					mask="url(#b)"
				/>
			</g>
		</svg>
	)
}

export function Female1Icon() {
	return (
		<svg
			width="45" height="45"
			viewBox="0 0 33 33"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<path
					id="a"
					d="M32 16c0 8.836-7.164 16-16 16-8.837 0-16-7.163-16-16S7.163 0 16 0c8.836 0 16 7.163 16 16"
				/>
				<path
					id="c"
					d="m1.455 0 .091.003 1.192 1.618a1.27 1.27 0 0 0-.59-.16h-.004c-.22 0-.47.077-.693.287a.135.135 0 1 0 .185.197.73.73 0 0 1 .512-.214h.002c.161 0 .315.055.427.111.056.028.101.057.132.077l.035.025.007.006.002.001a.134.134 0 0 0 .19-.019.132.132 0 0 0 .012-.016l.546.74s-.53 1.011-1.383 1.011a1.11 1.11 0 0 1-.146-.01L0 1.07S.501 0 1.455 0z"
				/>
				<path
					id="e"
					d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16z"
				/>
			</defs>
			<g transform="translate(.6 .251)" fill="none" fillRule="evenodd">
				<path
					d="M32 16c0 8.836-7.164 16-16 16-8.837 0-16-7.163-16-16S7.163 0 16 0c8.836 0 16 7.163 16 16"
					fill="#C6DDFF"
				/>
				<mask id="b" fill="#fff">
					<use xlinkHref="#a" />
				</mask>
				<path
					d="m9.748 22.912-.679 8.676H3.563A160.287 160.287 0 0 0 5.43 27.03c1.395-3.594 4.044-4.075 4.299-4.114l.02-.003M29.419 31.588h-5.657l-.903-8.775.009.003c.184.022 3.127.483 4.646 4.213.68 1.67 1.359 3.28 1.905 4.559"
					fill="#DE4A5D"
					mask="url(#b)"
				/>
				<path
					d="M24.333 31.588 16 33.244l-7.378-1.656.028-8.198c.32-.198.7-.354 1.078-.475.909-.289 1.797-.371 1.797-.371l4.947.498 4.946-.498s.67.062 1.44.269l.01.003c.48.127 1.001.314 1.423.574l.042 8.198"
					fill="#DE4A5D"
					mask="url(#b)"
				/>
				<path
					d="m9.749 22.911 5.83-1.532h2.01l5.27 1.435c-2.695 2.527-9.386 4.598-12.809.215l-.3-.118"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M24.977 17.576a3.72 3.72 0 0 1 .097-1.376c.588-2.255-.085-4.175-1.273-5.61-1.075-1.298-1.343-3.219-1.343-3.219-.79-2.601-3.464-4.406-5.899-4.406s-5.1 1.701-5.89 4.302c0 0-.356 2.06-1.351 3.323-1.152 1.462-1.861 3.355-1.273 5.61.116.444.152.916.097 1.376-.041.34-.134.693-.328.937h17.49c-.194-.244-.286-.597-.327-.937"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M13.527 12.627a1.712 1.712 0 1 1-3.422.097 1.712 1.712 0 0 1 3.422-.097"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M10.887 12.522a.496.496 0 0 1 .188-.033c.11 0 .26.028.434.146s.377.329.58.698a.097.097 0 0 0 .132.038.097.097 0 0 0 .038-.132c-.213-.389-.434-.626-.64-.765a.967.967 0 0 0-.544-.18c-.163 0-.264.049-.273.053a.097.097 0 1 0 .085.175"
					fill="#CB6D63"
					mask="url(#b)"
				/>
				<path
					d="M19.779 12.675a1.712 1.712 0 1 0 3.424 0 1.712 1.712 0 0 0-3.424 0"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M22.497 12.318a.642.642 0 0 0-.252-.045.952.952 0 0 0-.545.183c-.207.144-.427.39-.635.795a.098.098 0 1 0 .174.089c.197-.385.397-.602.572-.723a.754.754 0 0 1 .434-.15c.057 0 .102.008.131.016l.034.01.007.002a.098.098 0 0 0 .08-.177"
					fill="#CB6D63"
					mask="url(#b)"
				/>
				<path
					d="M21.984 9.415a5.378 5.378 0 1 1-10.755 0 5.378 5.378 0 0 1 10.755 0"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M18.308 16.093s-.741.68-1.417.847c-.675.167-1.333-.344-1.341-.35l-.79-.593-.317 5.636.087.094c1.148 1.232 2.8 1.249 3.957.04l.17-.176-.349-5.498"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M14.226 15.253s.95 3.293 2.647 3.26c1.394-.026 1.781-3.096 1.781-3.096l-4.428-.164"
					fill="#100305"
					mask="url(#b)"
				/>
				<path
					d="M21.276 9.473c.01 1.853-.26 3.453-.53 4.589-.264 1.112-1.02 1.892-2.092 2.522l-.195.115a3.874 3.874 0 0 1-3.76.058c-1.167-.636-1.987-1.48-2.244-2.67-.467-2.166-.529-4.566-.529-4.566s-.39-5.01 4.652-4.946c5.043.066 4.698 4.898 4.698 4.898"
					fill="#F0AA8E"
					mask="url(#b)"
				/>
				<path
					d="M16.098 12.218v.734c0 .025.01.05.028.069a.099.099 0 0 0 .07.028h.494a.097.097 0 1 0 0-.195h-.397v-.636a.097.097 0 1 0-.195 0"
					fill="#CB6D63"
					mask="url(#b)"
				/>
				<path
					d="m12.77 9.432 2.666-.24s-.238-.691-1.316-.58c-1.103.115-1.35.82-1.35.82M20.1 9.432l-2.666-.24s.239-.691 1.316-.58c1.104.115 1.35.82 1.35.82"
					fill="#000105"
					mask="url(#b)"
				/>
				<path
					d="M14.987 13.628h2.911s-.111.76-1.507.76c-1.146 0-1.404-.76-1.404-.76"
					fill="#FFFFFE"
					mask="url(#b)"
				/>
				<path
					d="M15.209 11.125c-.012-.007-.333-.278-.776-.281a1.003 1.003 0 0 0-.697.287.135.135 0 1 0 .184.198.73.73 0 0 1 .513-.214c.162 0 .316.054.428.11.056.028.102.057.132.078l.035.024.008.007h.002a.135.135 0 0 0 .171-.209M19.118 11.137c-.006-.006-.074-.079-.196-.152a1.001 1.001 0 0 0-.517-.148c-.218 0-.472.078-.732.287a.136.136 0 0 0 .169.211c.221-.176.408-.227.563-.227.156 0 .285.053.378.109a.806.806 0 0 1 .105.075l.026.024.006.005v.001l.039-.036-.039.036.039-.036-.039.036a.135.135 0 0 0 .198-.185"
					fill="#100305"
					mask="url(#b)"
				/>
				<g transform="translate(12.284 9.383)">
					<mask id="d" fill="#fff">
						<use xlinkHref="#c" />
					</mask>
					<path fill="#F3BAA2" mask="url(#d)" d="M0 3.667h3.5V0H0z" />
				</g>
				<mask id="f" fill="#fff">
					<use xlinkHref="#e" />
				</mask>
				<path
					d="M13.828 11.365a.135.135 0 0 1-.092-.234c.223-.21.472-.288.692-.288h.005c.244.002.45.085.59.16l.217.296a.135.135 0 0 1-.202.036l-.002-.002-.008-.006-.035-.025c-.03-.02-.076-.049-.132-.077a.973.973 0 0 0-.427-.11h-.001a.73.73 0 0 0-.513.213.135.135 0 0 1-.092.037"
					fill="#433632"
					mask="url(#f)"
				/>
				<path
					d="M19.209 13.051c-.048 0-.096-.003-.145-.01l-1.28-1.678a.14.14 0 0 0 .058-.027c.222-.177.409-.227.564-.228.156 0 .285.054.377.11a.806.806 0 0 1 .106.075l.026.024.006.005v.001a.136.136 0 0 0 .191.006.135.135 0 0 0 .007-.191c-.006-.007-.074-.079-.196-.152a1.001 1.001 0 0 0-.517-.149h-.003c-.218 0-.47.08-.73.287a.135.135 0 0 0-.036.045l-.546-.716s.501-1.07 1.455-1.07c.03 0 .06.002.092.004l1.954 2.653s-.53 1.01-1.383 1.01"
					fill="#F3BAA2"
					mask="url(#f)"
				/>
				<path
					d="M19.02 11.365a.136.136 0 0 1-.098-.042l-.001-.002-.006-.005-.026-.023a.806.806 0 0 0-.106-.076.734.734 0 0 0-.377-.11c-.155.001-.342.052-.563.228a.14.14 0 0 1-.058.027l-.148-.193a.135.135 0 0 1 .036-.045c.26-.208.512-.287.73-.287h.003c.219 0 .396.075.517.148.122.074.19.146.196.152a.135.135 0 0 1-.099.228"
					fill="#433632"
					mask="url(#f)"
				/>
				<path
					d="M15.94 11.23h-.07c0 .509-.206.969-.539 1.302-.333.333-.793.54-1.302.54-.508 0-.968-.207-1.302-.54a1.834 1.834 0 0 1-.539-1.302c0-.509.206-.968.54-1.302.333-.333.793-.539 1.301-.539.509 0 .969.206 1.302.54.333.333.54.792.54 1.301h.139a1.98 1.98 0 1 0-3.961 0 1.98 1.98 0 0 0 3.96 0h-.069M20.752 11.23h-.07c0 .509-.206.969-.539 1.302-.333.333-.793.54-1.302.54-.508 0-.968-.207-1.301-.54A1.836 1.836 0 0 1 17 11.23c0-.509.206-.968.54-1.302.333-.333.793-.539 1.301-.539.51 0 .969.206 1.302.54.333.333.54.792.54 1.301h.139a1.98 1.98 0 1 0-3.96 0 1.98 1.98 0 0 0 3.96 0h-.07"
					fill="#FFFFFE"
					mask="url(#f)"
				/>
				<path
					d="m15.964 11.295.039-.013a1.7 1.7 0 0 1 .473-.072c.14 0 .29.022.426.083l.057-.127a1.175 1.175 0 0 0-.483-.095c-.307 0-.556.092-.56.093l.048.131"
					fill="#FFFFFE"
					mask="url(#f)"
				/>
				<path
					d="M11.44 8.335s4.495-.856 5.423-4.211c0 0-4.782-1.697-5.423 4.21"
					fill="#100305"
					mask="url(#f)"
				/>
				<path
					d="M21.276 8.335s-4.495-.856-5.423-4.211c0 0 4.782-1.697 5.423 4.21"
					fill="#100305"
					mask="url(#f)"
				/>
			</g>
		</svg>
	)
}

export function Female2Icon() {
	return (
		<svg
			width="45" height="45"
			viewBox="0 0 33 33"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<path
					id="a"
					d="M32 16c0 8.836-7.163 16-16 16-8.836 0-16-7.163-16-16S7.163 0 16 0s16 7.163 16 16"
				/>
				<path
					id="c"
					d="m5.73 0-.177.06c3.565 2.53 8.324 2.647 11.86.075l.096-.07c2.843.564 4.33 2.307 4.83 3.65l.187.855a16.026 16.026 0 0 1-6.707 4.613H5A15.985 15.985 0 0 1 0 6.273l.56-2.558C1.08 2.319 2.664.486 5.732 0"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(.822 .251)">
					<mask id="b" fill="#fff">
						<use xlinkHref="#a" />
					</mask>
					<use fill="#C6DDFF" xlinkHref="#a" />
					<path
						d="M11.223 7.905s-.722 4.123-5.683 7.116c-5.289 3.192-2.274 13.333 6.987 13.556 10.06.243 15.173-12.326 9.358-22.59L11.223 7.904"
						fill="#100305"
						mask="url(#b)"
					/>
					<path
						d="M27.93 25.553c-.518-1.381-2.102-3.195-5.17-3.676l-5.719-1.122-5.718 1.122c-3.068.481-4.653 2.295-5.17 3.676l-1.2 5.41h5.248l.383.438 6.367 1.253 6.547-1.69h5.63l-1.198-5.411"
						fill="#FFFFFE"
						mask="url(#b)"
					/>
					<path
						d="m11.144 21.936 6.202-1.257 5.786 1.24-.128.092c-3.536 2.545-8.295 2.429-11.86-.075M13.948 12.762a1.673 1.673 0 1 1-3.344.13 1.673 1.673 0 0 1 3.344-.13"
						fill="#EA9D84"
						mask="url(#b)"
					/>
					<path
						d="M11.364 12.684a.487.487 0 0 1 .19-.034c.214.002.593.104.995.815a.094.094 0 1 0 .163-.092c-.417-.748-.872-.913-1.158-.91a.641.641 0 0 0-.273.054.093.093 0 1 0 .083.167"
						fill="#C0615C"
						mask="url(#b)"
					/>
					<path
						d="M20.059 12.746a1.674 1.674 0 1 0 3.348-.035 1.674 1.674 0 0 0-3.348.035"
						fill="#EA9D84"
						mask="url(#b)"
					/>
					<path
						d="M22.711 12.371a.626.626 0 0 0-.238-.042c-.139 0-.33.039-.532.18-.203.143-.417.386-.618.788a.094.094 0 0 0 .167.083c.19-.382.387-.597.559-.717a.738.738 0 0 1 .424-.147c.054 0 .096.008.125.015l.03.009.007.002a.094.094 0 1 0 .076-.171"
						fill="#C0615C"
						mask="url(#b)"
					/>
					<path
						d="M22.672 8.648c.03 2.967-2.522 5.4-5.702 5.432-3.179.034-5.781-2.346-5.812-5.313-.03-2.968 2.522-5.4 5.702-5.434 3.18-.032 5.781 2.347 5.812 5.315"
						fill="#100305"
						mask="url(#b)"
					/>
					<path
						d="M18.653 16.101s-.717.672-1.376.843c-.658.17-1.306-.324-1.314-.33l-.778-.57-.253 5.511.087.091c1.134 1.193 2.748 1.193 3.867 0l.164-.174-.397-5.37"
						fill="#EA9D84"
						mask="url(#b)"
					/>
					<path
						d="M14.656 15.322s.962 3.21 2.619 3.16c1.362-.04 1.71-3.045 1.71-3.045l-4.329-.115"
						fill="#100305"
						mask="url(#b)"
					/>
					<path
						d="M21.49 9.601a19.136 19.136 0 0 1-.471 4.49c-.248 1.09-.979 1.86-2.02 2.488l-.19.114a3.787 3.787 0 0 1-3.674.095c-1.148-.61-1.957-1.427-2.22-2.588-.479-2.112-.564-4.458-.564-4.458S11.92 4.848 16.85 4.86c4.93.013 4.641 4.74 4.641 4.74"
						fill="#EA9D84"
						mask="url(#b)"
					/>
					<path
						d="m16.456 12.157.007.707.028.066.067.027.483-.005a.094.094 0 0 0-.001-.187l-.39.004-.007-.614a.093.093 0 1 0-.187.002"
						fill="#C0615C"
						mask="url(#b)"
					/>
					<path
						d="m13.176 9.647 2.604-.262s-.24-.673-1.293-.553c-1.077.123-1.311.815-1.311.815M20.34 9.573l-2.609-.209s.227-.677 1.281-.579c1.08.101 1.328.788 1.328.788M15.216 11.211a.4.4 0 1 1-.798.008.4.4 0 0 1 .798-.008M19.13 11.17a.399.399 0 1 1-.797.009.399.399 0 0 1 .797-.009"
						fill="#100305"
						mask="url(#b)"
					/>
					<path
						d="M15.303 13.963c.01.005.503.265 1.354.266.447 0 .992-.072 1.615-.29a.093.093 0 1 0-.062-.177 4.699 4.699 0 0 1-1.553.28c-.406 0-.725-.063-.941-.124a2.312 2.312 0 0 1-.307-.111l-.014-.007-.004-.002a.094.094 0 0 0-.088.165"
						fill="#000105"
						mask="url(#b)"
					/>
					<path
						d="M21.478 8.488s-7.252.347-6.99-3.91c0 0 6.304-1.858 6.99 3.91"
						fill="#100305"
						mask="url(#b)"
					/>
					<path
						d="M12.231 8.587s2.538-1.093 2.51-3.705c0 0-2.608.328-2.51 3.705"
						fill="#100305"
						mask="url(#b)"
					/>
				</g>
				<g transform="translate(6.413 22.13)" fill="#E46E85">
					<mask id="d" fill="#fff">
						<use xlinkHref="#c" />
					</mask>
					<path
						mask="url(#d)"
						d="M-1.332.663h25.387M-.006.804h22.548V.521H-.006zM-1.332 1.988h25.387M-.006 2.13h22.548v-.283H-.006zM-1.332 3.314h25.387M-.006 3.455h22.548v-.283H-.006zM-1.332 4.639h25.387M-.006 4.781h22.548v-.283H-.006zM-1.332 5.965h25.387M-.006 6.106h22.548v-.282H-.006zM-1.332 7.29h25.387M-.006 7.432h22.548v-.283H-.006zM-1.332 8.616h25.387M-.006 8.758h22.548v-.283H-.006z"
					/>
				</g>
			</g>
		</svg>
	)
}