import { toast } from 'react-toastify';
// import Dinero from 'dinero.js';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

// Used to check status
export const constAPISuccess = "success"
export const constAPIError = "error"

// Used for error / success messages
export const constAdd = "Add"
export const constDelete = "Delete"
export const constUpdate = "Update"
export const constCancel = "Cancel"

export const constAccountTypeEngineer = "engineer"

export const constAddFailed = "AddFailed"
export const constDeleteFailed = "DeleteFailed"
export const constUpdateFailed = "UpdateFailed"
export const constViewFailed = "ViewFailed"
export const constListFailed = "ListFailed"
export const constCancelFailed = "CancelFailed"
export const constRecordNotFound = "recordNotFound"

// Used to pass control type in handleInputChange
export const constInput = {
	reactSelect: "reactSelect",
	checkbox: "checkbox"
}

// Used for Validation
export const ErrFieldRequired = "Required"
export const ErrFieldCanNotBeZero = "Can't be 0"

export const ConstLengthAddress = 75
export const ConstLengthZipCode = 11
export const ConstLengthMobile = 10
export const ConstLengthEmail = 50
export const ConstLengthName = 50

export const ConstTaxFilterGST = "sgst" //Value can also be cgst
export const ConstTaxFilterIGST = "igst"
export const ConstTaxTypeGST = "gst"

export const ConstCountryIndia = "India"

// export const ConstRole = {
//     Admin: '21',
//     User: '22'    
// }

export const ConstKVPNameToken = "$sot"
export const ConstKVPNameFirstName = "$firstName"
export const ConstKVPNameLastName = "$lastName"
export const ConstKVPNameOrganizationID = "$organizationID"
export const ConstKVPNameRole = "$role"

export const ConstLoginURL = "/login"
export const ConstLogoutURL = "/logout"

//Reducer Action types
export const actionTypes = {
	onChange: 'onChange',
	loading: 'loading',
	success: 'success',
	error: 'error',
	list: 'list'
}

export const getMessage = (type, placeholder) => {
	switch (type) {
		case constAdd:
			return `${placeholder} added`
		case constUpdate:
			return `${placeholder} updated`
		case constDelete:
			return `${placeholder} deleted`
		case constCancel:
			return `${placeholder} cancelled`
		case constAddFailed:
			return `${placeholder} not added`
		case constUpdateFailed:
			return `${placeholder} not updated`
		case constDeleteFailed:
			return `${placeholder} not deleted. There many be records associated with it.`
		case constViewFailed:
			return `Unable to get ${placeholder}`
		case constListFailed:
			return `Unable to get ${placeholder}`
		case constRecordNotFound:
				return `Item not found.`
		default:
			break;
	}
}


// Returns 
// isRecordNotFound
// errors - transformed array structure
export const processError = (data) => {
	var errors = {}
	var isRecordNotFound = false
	if (data) {
		data.forEach(data => {
			if (data.field === "_global_" && data.code === constRecordNotFound) {
				isRecordNotFound = true
			}
			errors[data.field] = { "code": data.code, "message": data.message }
		});
	}
	return [isRecordNotFound, errors]
}

export const processException = (error) => {
	// console.log(error);
	// Error 😨
	if (error.response) {
		/*
		* The request was made and the server responded with a
		* status code that falls out of the range of 2xx
		*/
		// console.log(error.response.data);
		// console.log(error.response.status);
		// console.log(error.response.headers);

		// Unauthorized Error
		if (error.response.status === 401) {
			window.location = ConstLogoutURL
			return
		}

	}	
	if (error && error.message) {
		//AxiosError
		// Something happened in setting up the request and triggered an Error
		if (error.code === "ERR_NETWORK") {
			toast.error("Network Error. Please check your connectivity.");
			return
		} else {
			toast.error(error.message);
			return
		}
	}
	if (error.request) {
		/*
		* The request was made but no response was received, `error.request`
		* is an instance of XMLHttpRequest in the browser and an instance
		* of http.ClientRequest in Node.js
		*/
		toast.error(' Check you internet connectivity.');
		return
	}
	
}

//https://stackoverflow.com/questions/14226803/wait-5-seconds-before-executing-next-line
export const delay = (function () {
	var timer = 0;
	return function (callback, ms) {
		clearTimeout(timer);
		timer = setTimeout(callback, ms);
	};
})();

/**
 * set the value to window and save it in local storage.
 * @param {string} key 
 * @param {string} value 
 * @param {boolean} skipWindow
 */
export const setKVP = (key, value, skipWindow = false) => {
	localStorage.setItem(key, value)
	if (!skipWindow) {
		window[key] = value
	}
}

export const getKVP = (key) => {
	var value = window[key]
	if (isNullOrEmpty(value)) {
		value = localStorage.getItem(key)
	}
	if (isNullOrEmpty(value)) {
		value = ""
	}
	window[key] = value
	return value
}

export const deleteKVP = (key = "") => {
	if (!isNullOrEmpty(key)) {
		localStorage.removeItem(key)
		window[key] = ""
	} else {
		//TODO: Fix it. Doen't return all the storage length. Few items are left.
		//Remove all items from the local storage when key is empty
		for (let index = 0; index < localStorage.length; index++) {
			const elementName = localStorage.key(index);
			console.log(elementName)
			window[elementName] = ""
			localStorage.removeItem(elementName)
		}
		localStorage.clear()
	}
}


// export const setToken = (token) => {
// 	localStorage.setItem("sot", token)
// 	window.$sot = token
// }

export const getToken = () => {
	return getKVP(ConstKVPNameToken)
	// var token = window.$sot
	// if (token == "" || token == null || token == undefined) {
	// 	token = localStorage.getItem("sot")
	// }
	// if (token == null || token == undefined) {
	// 	token = ""
	// }
	// return token
}

// export const deleteToken = () => {
// 	localStorage.removeItem("sot")
// 	window.$sot = ""
// }

export const getPageSize = () => {
	return 10
}

export const getCurrency = () => {
	//TODO: Fix currency to get from window
	// var currency = window.$currencySymbol	
	// if (currency == "" || currency == null || currency == undefined) {
	// 	// currency = localStorage.getItem("sot")
	// }
	// if (currency == null || currency == undefined) {
	// 	currency = ""
	// }
	return { symbol: "Rs", code: "INR" }
}

// export const setCurrency = (token) => {
	// localStorage.setItem("sot", token)
	// window.$currencySymbol = token
// }

// Takes amount value, create and return Dinero object
// export const getDineroObject = (amount, precision = 2, currency = 'INR', locale = 'en-IN') => {
// 	console.group("getDineroObject")
// 	console.log("amount")
// 	console.log(amount)
// 	var amt = amount //(amount === '' ? 0 : amount)
// 	if (amt === '' || Number.isNaN(amt)) {
// 		amt = 0
// 	}
// 	var decimals = countDecimals(amount)
// 	// If precision is null then use decimals counted else force to use preceison as passed in parameter
// 	// precision = (precision === null ? decimals : precision)
// 	if (isNullOrEmpty(precision)) {
// 		precision = decimals
// 	} else if (precision > decimals) {
// 		//If precision is > decimals then alter to accomodate additional 0's
// 		decimals = precision
// 	} else if (decimals > precision) {
// 		decimals = precision
// 	}

// 	// decimals = (decimals < precision ? precision : decimals)
// 	console.log("decimals")
// 	console.log(decimals)
// 	console.log("precision")
// 	console.log(precision)
// 	for (var i = 1; i <= decimals; i++) {
// 		amt = amt * 10
// 	}
// 	console.log("converted amount")
// 	console.log(amt)
// 	console.groupEnd()
// 	return Dinero({ amount: parseInt(amt), precision: precision, currency: currency }).setLocale(locale)
// }

// //Takes dinero object and returns formatted amount as string
// export const getDineroAmount = (dinero, format = "0.00") => {
// 	//TODO: may be required to round off using float and then convert it to Amount. This may fix round off issue.

// 	// This will fail for var a = parseFloat(parseFloat(2.51).toFixed(2))
// 	// return parseFloat(parseFloat(amount).toFixed(2))
// 	console.group("getDineroAmount")
// 	console.log("amount")
// 	console.log(dinero.getAmount())
// 	console.log("precision")
// 	console.log(dinero.getPrecision())
// 	console.groupEnd()
// 	var fmt = "0"
// 	for (var i = 1; i <= dinero.getPrecision(); i++) {
// 		if (i === 1) { fmt = fmt + ".0" }
// 		else { fmt = fmt + "0" }
// 	}
// 	return dinero.toFormat(fmt)
// }

export const countDecimals = (val) => {
	//https://stackoverflow.com/questions/17369098/simplest-way-of-getting-the-number-of-decimals-in-a-number-in-javascript
	if (Math.floor(val.valueOf()) === val.valueOf()) return 0;

	var str = val.toString();
	if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
		return str.split("-")[1] || 0;
	} else if (str.indexOf(".") !== -1) {
		// console.log(str.split("."))
		return str.split(".")[1].length || 1; // || 0;
	}
	return str.split("-")[1] || 0;
}

//TODO: Deprecated. REmove this
export const getFormattedAmount = (amount) => {
	//TODO: Fix this using dinerojs
	// This will fail for var a = parseFloat(parseFloat(2.51).toFixed(2))
	return parseFloat(parseFloat(amount).toFixed(2))
}

// For calendar control
export const getDateFormat = () => {
	//TODO: Get from localstorage settings
	return "Y-m-d"
}

//For dayjs
export const getDateDisplayFormat = () => {
	//TODO: Get from localstorage settings
	return "YYYY-MM-DD"
}

export const getDateTimeFormat = () => {
	//TODO: Get from localstorage settings
	return "Y-m-d H:i"
}

// Used for platpickr because when Z is present in date time then flatpickr converts to local system time
//https://bobbyhadz.com/blog/javascript-convert-iso-string-to-date-object#:~:text=Use%20the%20Date()%20constructor,will%20return%20a%20Date%20object.
export const removeTimeZoneZ = (time) => {
	if (!isNullOrEmpty(time)) {
		return time.replace("Z", "")
	} else {
		return time
	}
}

export const formatDate = (dt) => {
	if (!isNullOrEmpty(dt)) {
		const obj = dayjs(dt).format(getDateDisplayFormat());
		return obj
	} else {
		return ""
	}
}

export const getBadgeInfo = (statusCode) => {
	const kvp = [

		{ key: "11", name: "Active", badgeColorCSS: "bg-success", canEdit: true },
		{ key: "12", name: "Suspended", badgeColorCSS: "bg-warning text-dark", canEdit: true  },
		{ key: "21", name: "Admin", badgeColorCSS: "bg-info text-dark" , canEdit: true  },
		{ key: "22", name: "User", badgeColorCSS: "bg-success" , canEdit: true  },

		{ key: "501", name: "Draft", badgeColorCSS: "bg-secondary", canEdit: true },
		{ key: "502", name: "Issued", badgeColorCSS: "bg-primary", canEdit: false  },
		{ key: "503", name: "Partially Received", badgeColorCSS: "bg-info text-dark" , canEdit: false  },
		{ key: "504", name: "Received", badgeColorCSS: "bg-info text-dark" , canEdit: false  },
		{ key: "505", name: "Partially Shipped", badgeColorCSS: "bg-info text-dark" , canEdit: false  },
		{ key: "506", name: "Shipped", badgeColorCSS: "bg-info text-dark" , canEdit: false  },
		{ key: "507", name: "Completed", badgeColorCSS: "bg-success" , canEdit: false  },
		{ key: "508", name: "Cancelled", badgeColorCSS: "bg-warning text-dark", canEdit: false },
		
		{ key: "601", name: "Accept", badgeColorCSS: "bg-success", canEdit: true },
		{ key: "602", name: "Reject", badgeColorCSS: "bg-warning text-dark", canEdit: false  },
		{ key: "603", name: "Shortage", badgeColorCSS: "bg-info text-dark" , canEdit: false  },
		{ key: "604", name: "Deliver", badgeColorCSS: "bg-success" , canEdit: false  },

	]
	let val = null
	kvp.forEach(e => {
		if (e.key === String(statusCode)) {
			val = e
		}
	});
	return val

}


// Check if the value is null or undefined or empty
export const isNullOrEmpty = (data) => {
	if (data === null || data === undefined || data === '') {
		return true
	} else {
		return false
	}
}

export const getAddressStruct = (address1 = "", address2 = "", city = "", state = "", zip_code = "", country = "") => {
	return { Address1: address1, Address2: address2, City: city, State: state, ZipCode: zip_code, Country: country }
}

// check the tax override settings and return whether drop should be disabled or enabled
export const shouldDisableTaxOverride = () => {
	// Meaning tax override is enabled and if disabled then return true
	return "true";
}

// Tax type (sgst/igst) and tax information from item master
export const getTaxGroupID = (taxType, itemTax) => {
	if (taxType === ConstTaxFilterGST) {
		return itemTax.GSTTaxGroupID
	} else if (taxType === ConstTaxFilterIGST) {
		return itemTax.IGSTTaxGroupID
	} else {
		return null
	}
}

// To find whether CGST/IGST is applicable if the country is India
export const findTaxType = (sourceCountry = "", sourceState = "", destinationCountry = "", destinationState = "") => {

	if (sourceCountry === ConstCountryIndia && destinationCountry === ConstCountryIndia) {
		if (sourceState === destinationState) {
			return ConstTaxFilterGST
		} else {
			return ConstTaxFilterIGST
		}
	} else {
		// Irrespective of country set the tax to IGST
		return ConstTaxFilterIGST
	}
}