import React from 'react'
import { LoadingIcon } from './icons'

/**
 * Custom button
 * @param props
 * @param  {boolean} props.isProcessing - True will show loading icon
 * @param  {string} props.style - primary (default), outline-primary, .. secondary, success, danger, warning, info, light, dark
 * @param  {string} props.size - sm, md (default), lg
 * @param  {string} props.css - css class name
 * @param  {string} props.value - button display text
 * @param  {string} props.type - button (default), submit, rest
* @param  {Object} props.prefixIcon - Pass SVG icon
* @param  {Object} props.suffixIcon - Pass SVG icon
* @param  {string} props.onClick
 */
export function ADButton({ isProcessing = false, style = "primary", size = "md", css = "", value = "", type = "button", prefixIcon ="", suffixIcon="", onClick } ) {
	//const { isProcessing, style, size, css, ...attr } = props
	css = css + (style === "primary" ? " text-white" : "")
	var cssSelected = `btn btn-${style} btn-${size} btn-loader me-1 ${css}`

	return (
		<React.Fragment>
			{isProcessing === true &&				
				<button className={cssSelected} type={type} disabled>
					<LoadingIcon />
					{prefixIcon}{value}{suffixIcon}
				</button>
			}
			{isProcessing === false &&
				<button className={cssSelected} type={type} onClick={onClick}>{prefixIcon} {value} {suffixIcon}</button>
			}
		</React.Fragment>
	)
}