import React, { useState, useEffect, useRef, Fragment } from 'react'
import { isNullOrEmpty } from '../../utils/helper'
import { ADLabel } from './label'

/**
 * Form Input - Text, Number, Email and Grouped labels. Note: Input value attribute must be set for number validation to work.
 * @param props
 * @param  {string} param.id
 * @param  {string} param.name
 * @param  {string} param.label
 * @param  {string} param.required - default to false
 * @param  {string} param.type - text, number, email
 * @param  {string} param.css - css class names
 * @param  {string} param.placeholder
 * @param  {string} param.value
 * @param  {boolean} param.disabled
 * @param  {number} param.maxLength
 * @param  {string} param.onChange
 * @param  {string} param.error - Error message to be shown
 * @param  {string} param.description - Description text
 * @param  {boolean} param.allowNegative - Allow negative numbers
 * @param  {boolean} param.allowDecimal - Allow decimals numbers 
 * @param  {string} param.prefixLabel - Prefix Label for grouping, if any
 * @param  {string} param.suffixLabel - Suffix Label for grouping, if any
 * @param  {string} param.marginBottom - Margin bottom of the parent most div
 * @param  {object} param.action - Button object used for action Example: Search
 * 
*/

export function ADInput({
	id, name, label, required=false, type = "text", css="", placeholder,
	value, disabled, maxLength, onChange, error, description,
	allowNegative=false, allowDecimal=false, prefixLabel, suffixLabel, marginBottom = "mb-3", action = "",
}) {
	// const { label, placeholder, , ...attr } = props
	//https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
	const styles = (!isNullOrEmpty(error) ? 'is-invalid' : '')
	const [cursor, setCursor] = useState(0)
	var shouldRenderGroup = false
	if (!isNullOrEmpty(prefixLabel) || !isNullOrEmpty(suffixLabel) || !isNullOrEmpty(action)) {
		shouldRenderGroup = true
	}

	var inputMode = 'text'
	if (type === 'number') {
		type = 'text'
		// Only if it is decimal
		if (allowDecimal) {
			inputMode = 'decimal'
		}	
	}

	// Default regex for positive numbers with out negative numbers
	var re = /^[0-9]*$/
	// 43.9
	if (allowDecimal && allowNegative === false) {
		re = /^\d*\.?\d*$/
	}
	// -43.9
	else if (allowDecimal && allowNegative === true) {
		re = /^\-?\d*\.?\d*$/
	}
	// -43
	else if (allowDecimal === false && allowNegative) {
		re = /^\-?\d*$/
	}

	const inputRef = useRef(null)

	const onChangeInner = (e) => {
		if (e.target.inputMode === 'decimal') {
			var cursor = e.target.selectionStart;
			var beforeCursor = e.target.value.slice(0, cursor);
			setCursor(beforeCursor.length)
			if (e.target.value === '' || re.test(e.target.value)) {
				onChange(e)
			}
		} else if (allowDecimal === false) {
			if (e.target.value === '' || re.test(e.target.value)) {
				onChange(e)
			}
		} else {
			onChange(e)
		}
	}

	useEffect(() => {
		if (inputRef.current.inputMode === 'decimal') {
			inputRef.current.selectionStart = cursor
			inputRef.current.selectionEnd = cursor
		}
	})
	//NOTE: Not using props.value as this would restrict cursor setting only on value change. Decimal (.) doesn't trigger change value so cursor is taken to last position automatically.

	return (
		<Fragment>
			<div className={marginBottom}>
				{label &&
					<ADLabel
						htmlFor={id} css="mb-1">
						{label} {!required && <small className="text-muted fw-normal">- Optional</small>}
					</ADLabel>
				}

				{shouldRenderGroup === true &&
					<div className="input-group">
						{prefixLabel &&
							<span className="input-group-text bg-white">{prefixLabel}</span>
						}					
						<input
							ref={inputRef}
							type={type}
							inputMode={inputMode}
							className={`form-control ${styles} ${css}`}
							id={id}
							name={name}
							placeholder={placeholder}
							onChange={onChangeInner}
							value={value}
							required={required}
							maxLength={maxLength}
							disabled={disabled} />
						{suffixLabel &&
							<span className="input-group-text bg-white">{suffixLabel}</span>
						}
						{/* {action && */}
						{action}
						{/* } */}
					</div>
				}
				{shouldRenderGroup === false &&
					<input
						ref={inputRef}
						type={type}
						inputMode={inputMode}
						className={`form-control ${styles} ${css}`}
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChangeInner}
						value={value}
						required={required}
						maxLength={maxLength}
						disabled={disabled} />
				}

				{/* <RenderInput> */}

				{/* </RenderInput> */}

				{error &&
					<small className="form-text text-danger">{error.message}</small>
				}
				{description &&
					<small className="form-text text-muted">{description}</small>
				}

			</div>
		</Fragment>
	)
}
