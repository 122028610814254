import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
const LoginPage = (props) => {
	let navigate = useNavigate()
	useEffect(() => {	  
		navigate(`/9da71a27-fdd4-42bf-8181-bdbbb9fb487b/job`)
	}, [])
	
	return (
		<>
			<div className="container-xl">
				<h1 className="app-page-title">Login</h1>				
			</div>
		</>
	)
}

export default LoginPage;