import React, { Fragment } from "react"
import { InvalidURLIcon, NoRecordsIcon } from './icons'

export function AD404Error() {
  return (
    <Fragment>
      <div className="text-center bg-white text-muted pt-5 pb-5">
        <InvalidURLIcon />
        <p className="pt-2">There's nothing here! URL doesn't exists.</p>
      </div>
    </Fragment>
  )
}


