import React, { useState } from "react"

export function ADJobStatus({ status }) {
	var css = ""

	if (status === "Booked") {
		css = "badge-warning"
	} else if (status === "Assigned") {
		css = "badge-info"
	} else if (status === "Engineer on the way") {
		css = "badge-primary"
	}
	else if (status === "Job Completed") {
		css = "badge-success"
	}
	else if (status === "Job in Progress") {
		css = "badge-primary"

	} else if (status === "Job Not Approved") {
		css = "badge-danger"

	} else if (status === "Job Cancelled") {
		css = "badge-danger"
	}
	return (
		<React.Fragment>
			<span className={`${css} badge rounded-pill d-inline`}>{status}</span>
		</React.Fragment>
	)
}