import React from 'react'
const AboutPage = (props) => {
	return (
		<>				
			<div className="container-xl">
				<h1 className="app-page-title">About</h1>
				<div className="row">
					<div className="col-12">
						<div className="app-card shadow-sm mb-1 p-4">
							<div className="app-card-body">
								Version 1.2 - 13-April-2022								
							</div>
						</div>						
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="app-card shadow-sm mb-1 p-4">
							<div className="app-card-body">
								Version 1.0 - 31-Aug-2021								
							</div>
						</div>						
					</div>
				</div>
			</div>
		</>
	)
}

export default AboutPage;