import React, { Fragment } from 'react'
import { ADLabel } from './label'
/**
 * Form Input - Text Area
 * @param props
 * @param  {string} param.id
 * @param  {string} param.name
 * @param  {string} param.label
 * @param  {string} param.required - default is false
  * @param  {number} param.rows - number of rows
 * @param  {string} param.css - css class names
 * @param  {string} param.placeholder
 * @param  {string} param.value
 * @param  {boolean} param.disabled
 * @param  {number} param.maxLength
 * @param  {string} param.onChange
 * @param  {string} param.error - Error message to be shown
 * @param  {string} param.description - Description text
*/

export function ADInputArea({
	id, name, label, required=false, rows, css="", placeholder,
	value, disabled, maxLength, onChange, error, description,
}){
	// const { label, placeholder, , ...attr } = props
	return (
		<Fragment>
			<div className="mb-3">
				{label &&
					<ADLabel
					htmlFor={id} css="mb-1">
						{label} {!required && <small className="text-muted fw-normal">- Optional</small>}
					</ADLabel>
				}

				<textarea
					className={`form-control h-100 ${css}`}
					id={id}
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					value={value}
					required={required}
					rows={rows}
					maxLength={maxLength}
					disabled={disabled}></textarea>

				{error &&
					<small className="form-text text-danger">{error.message}</small>
				}
				{description &&
					<small className="form-text text-muted">{description}</small>
				}
			</div>
		</Fragment>
	)
}