import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateContactAPI, ListContactAPI } from '../api/contactAPI';
import { ADSelectCountry } from '../components/select';
import { ADButton } from '../components/ui/button';
import { ADCard, ADCardBody, ADCardFooter } from '../components/ui/card';
import { SearchIcon, NewIcon, Men1Icon, Man1Icon, DownShortArrowIcon } from '../components/ui/icons';
import { ADInput } from '../components/ui/input';
import { ADLoading } from '../components/ui/loading';
import { ADNoRecords } from '../components/ui/noRecords';
import { ADPageActions, ADPageHeader, ADPageTitle } from '../components/ui/page';
import { ADTable, ADTableBody, ADTableHead, ADTableTD, ADTableTH, ADTableTR } from '../components/ui/table';
// import { useContact } from '../hooks/useContact';
import { useOrganizationID } from '../hooks/useOrganizationID';
import { useParamSearchTerm } from '../hooks/useParamSearchTerm'
import { constAPISuccess, constRecordNotFound, getMessage, processError, constAdd, actionTypes, constAPIError, constInput, isNullOrEmpty, getPageSize } from '../utils/helper';
import { contactReducer } from '../reducers/contactReducer'
import { ADAlert } from '../components/ui/alert';
import { ADRandomPersonIcon } from '../components/randomPersonIcon';


export function ListContactPage() {

	//Initialize variables
	let organizationID = useOrganizationID();
	let navigate = useNavigate()
	const initialState = { status: "", data: [], errors: {}, loading: false, size: getPageSize(), skip: 0 }
	const [state, dispatch] = useReducer(contactReducer, initialState)
	let searchTerm = useParamSearchTerm()

	// Handle input changes
	const handleInputChange = (event, name = "", control = "") => {
		//For Select dropdown
		if (control === constInput.reactSelect) {
			let val = !isNullOrEmpty(event) ? event.value : null
			dispatch({
				type: actionTypes.onChange,
				name: name, value: val
			})
		}
		//For check box
		else if (event.target.type == constInput.checkbox) {
			dispatch({
				type: actionTypes.onChange,
				name: event.target.name, value: event.target.checked ? 1 : -1
			})
		}
		//For all other input controls
		else {
			dispatch({
				type: actionTypes.onChange,
				name: event.target.name, value: event.target.value
			})
		}
	};

	// Trigger on page laod
	useEffect(() => {
		list(getPageSize(), state.skip, searchTerm)
	}, [])

	// Get Contacts from remote
	const list = async (size, skip, search) => {
		dispatch({ type: actionTypes.loading })
		await ListContactAPI(organizationID, size, skip, search).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				var obj = []
				data.data.forEach(e => {
					obj.push({ id: e.id, name: e.name, email: e.email, mobileCountryCode: e.mobileCountryCode, mobileNumber: e.mobileNumber })
				});
				dispatch({
					type: actionTypes.list,
					data: obj.map(e => { return e }),
					skip: (skip + size)
				})
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})
	}

	// Handle New Contact button
	const handleNewContact = (event) => {
		navigate(`/${organizationID}/contact/create`)
	}

	// Render Contacts into a table
	const ListContact = () => {
		return (
			<Fragment>
				{!state.loading && state.data && state.data.length === 0 &&
					<ADNoRecords />
				}
				{state.data && state.data.length > 0 &&
					<ADTable key="list" css='app-card-table' >
						<ADTableHead>
							<ADTableTR>
								<ADTableTH></ADTableTH>
								<ADTableTH>Name</ADTableTH>
								<ADTableTH>Email</ADTableTH>
								<ADTableTH>Mobile</ADTableTH>
							</ADTableTR>
						</ADTableHead>
						<ADTableBody>
							{listContactItem}
						</ADTableBody>
					</ADTable>

				}
				{state.loading &&
					<ADLoading />
				}
			</Fragment>
		)
	}

	// Render a contact in a row
	const listContactItem = state.data.map((data, index) => {
		return (
			<ADTableTR key={index}>
				<ADTableTD><ADRandomPersonIcon /></ADTableTD>
				<ADTableTD>{data.name} <span class="badge badge-success rounded-pill d-inline">Active</span></ADTableTD>
				<ADTableTD>{data.email}</ADTableTD>
				<ADTableTD>{data.mobileNumber}</ADTableTD>
			</ADTableTR>
		)
	});

	return (
		<>
			<div className="container-xl">
				<ADPageHeader>
					<ADPageTitle>Contacts</ADPageTitle>
					<ADPageActions>
						<div className="col-auto">
							<form>
								<ADInput
									id="search"
									name="search"
									required={true}
									placeholder="Search Name, Email, Mobile"
									marginBottom="mb-0"
									value={searchTerm}
									action={
										<ADButton
											key="search"
											isProcessing={false}
											style="light"
											type="submit"
											prefixIcon={<SearchIcon />}
										/>
									}
								/>
							</form>
						</div>
						<div className="col-auto">
							<ADButton value="New Contact" prefixIcon={<NewIcon />} css="mb-0" onClick={handleNewContact} />
						</div>
					</ADPageActions>
				</ADPageHeader>

				<div className="row">
					<div className="col-12">
						<ADCard>
							<ADCardBody>
								<ListContact />
							</ADCardBody>
							<ADCardFooter>
								<div className='d-flex justify-content-center'>
									<ADButton
										onClick={(e) => { list(getPageSize(), state.skip, searchTerm) }}
										style="light rounded-pill"
										isProcessing={state.loading}
										value="Load More"
										suffixIcon={<DownShortArrowIcon />}
									/>
								</div>
							</ADCardFooter>
						</ADCard>
					</div>
				</div>
			</div>
		</>
	)
}

export function CreateContactPage() {

	let organizationID = useOrganizationID();
	let navigate = useNavigate()
	const [error, setError] = useState({})

	const initialState = { status: "", data: { email: "", mobileCountryCode: "", mobileNumber: "", name: "", source: "" }, errors: null, loading: false }
	const [state, dispatch] = useReducer(contactReducer, initialState)

	const handleInputChange = (event, name = "", control = "") => {
		if (control === constInput.reactSelect) { //For Select dropdown
			let val = !isNullOrEmpty(event) ? event.value : null
			dispatch({ type: actionTypes.onChange, name: name, value: val })
		} else if (event.target.type == constInput.checkbox) { //For check box
			dispatch({ type: actionTypes.onChange, name: event.target.name, value: event.target.checked ? 1 : -1 })
		} else { //For all other input controls
			dispatch({ type: actionTypes.onChange, name: event.target.name, value: event.target.value })
		}
	};

	const handleCancelClick = (e) => {
		navigate(`/${organizationID}/contact`)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault()
		// setError({})
		create()
	}

	const create = async () => {
		dispatch({ type: actionTypes.loading })
		const payload = { email: state.data.email, mobileCountryCode: state.data.mobileCountryCode, mobileNumber: state.data.mobileNumber, name: state.data.name }
		await CreateContactAPI(organizationID, payload).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				dispatch({
					type: actionTypes.success,
					data: { ID: data.id }
				})
				toast.success(getMessage(constAdd, 'Contact'));
				navigate(`/${organizationID}/contact`)
			} //on error
			else if (data && data.status === constAPIError) {
				const [isRecordNotFound, err] = processError(data.errors)
				if (isRecordNotFound) {
					toast.error(getMessage(constRecordNotFound))
					navigate(`/${organizationID}/contact`)
				} else {
					dispatch({
						type: actionTypes.error,
						errors: err
					})
				}
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})
	}

	return (
		<>
			<ADAlert error={state && state.errors} />
			<div className="container-xl">
				<ADPageHeader>
					<ADPageTitle>New Contact</ADPageTitle>
				</ADPageHeader>

				<form onSubmit={handleFormSubmit} encType="multipart/form-data">
					<div className="row">
						<div className="col-12 col-lg-8">
							<ADCard>
								<ADCardBody>
									<ADInput
										id="name"
										name="name"
										type="text"
										label="Name"
										required={true}
										placeholder="Ex. Harry"
										maxLength={50}
										value={state.data.name}
										error={state && state.errors && state.errors.name}
										onChange={handleInputChange}
									/>

									<ADInput
										id="email"
										name="email"
										type="email"
										label="Email"
										placeholder="yourname@gmail.com"
										maxLength={50}
										value={state.data.email}
										error={state && state.errors && state.errors.email}
										onChange={handleInputChange}
									/>
									<div className="row">
										<div className="col-6 col-sm-4">
											<ADSelectCountry
												id="mobileCountryCode"
												name="mobileCountryCode"
												label={"Country Code"}
												showCountryCode={true}
												isClearable={true}
												value={state.data.mobileCountryCode}
												onChange={(e) => { handleInputChange(e, "mobileCountryCode", constInput.reactSelect) }}
												error={state && state.errors && state.errors.mobileCountryCode}
											/>
										</div>
										<div className="col-6 col-sm-8">
											<ADInput
												id="mobileNumber"
												name="mobileNumber"
												type="number"
												label="Mobile Number"
												maxLength={11}
												value={state.data.mobileNumber}
												error={state && state.errors && state.errors.mobileNumber}
												onChange={handleInputChange}
											/>
										</div>
									</div>
								</ADCardBody>
							</ADCard>
						</div>
					</div>

					<div className="row">
						<div className="col-8">
							<div className="row justify-content-between">
								<div className="col-auto">
									<ADButton
										style="light"
										isProcessing={false}
										value="Cancel"
										size="lg"
										onClick={handleCancelClick}
									/>
								</div>
								<div className="col-auto">
									<ADButton
										type='submit'
										isProcessing={state.loading}
										value="Save"
										size="lg"
									/>
								</div>
							</div>
						</div>
					</div>

				</form>
			</div>
		</>
	)
}
