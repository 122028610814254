import Server from './server'
import { processException } from '../utils/helper'
import { useOrganizationID } from '../hooks/useOrganizationID';

export async function ListJobAPI(organizationID, size, skip, search = "") {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().get(`/job?organizationID=${organizationID}&size=${size}&skip=${skip}&search=${search}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function GetJobAPI(organizationID, id) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().get(`/job/${id}?organizationID=${organizationID}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function GetJobSummaryAPI(organizationID) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().get(`/job/summary?organizationID=${organizationID}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function CreateJobAPI(organizationID, data) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().post(`/job?organizationID=${organizationID}`, data);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function UpdateJobAPI(organizationID, id, data) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().put(`/job/${id}?organizationID=${organizationID}`, data);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function UpdateJobScheduleAPI(organizationID, id, scheduledAt) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().put(`/job/${id}/schedule?organizationID=${organizationID}&scheduledAt=${scheduledAt}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function UpdateJobEngineerAPI(organizationID, id, engineerID) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().put(`/job/${id}/engineer?organizationID=${organizationID}&engineerID=${engineerID}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function DeleteJobAPI(organizationID, id) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().delete(`/job/${id}?organizationID=${organizationID}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}