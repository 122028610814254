import React, { Fragment, useEffect } from "react"
import { toast } from "react-toastify"

/**
 * Alert
 * @param props
 * @param  {object} props.error
 */
export function ADAlert({ error }) {
	useEffect(() => {	  
		if (error && error._global_) {
			toast.error(error._global_.message)
		}
	}, [error])
	
	return (
		<Fragment>
			{/* {error && error._Global_ &&
				// <div className="alert alert-danger alert-dismissible fade show" role="alert">
				// 	{error._Global_.message}
				// </div>
				toast.error(error._Global_.message)
			} */}
		</Fragment>
	)
}


