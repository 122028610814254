import React, { useEffect, useState } from "react"
import Select from "react-select"
import { ListAccountAPI } from "../api/accountAPI"
import countries from "../data/countries.json"
import { useOrganizationID } from "../hooks/useOrganizationID"
import { constAccountTypeEngineer, constAPISuccess, getPageSize, isNullOrEmpty } from "../utils/helper"
import { ADLabel } from "./ui/label"


/**
 * Custom Country Select dropdown
 * @param props
 * @param  {string} props.id
 * @param  {string} props.name
 * @param  {string} props.label
 * @param  {boolean} props.required
 * @param  {boolean} props.showCountryCode
 * @param  {boolean} props.showFlag
 * @param  {string} props.value
 * @param  {boolean} props.disabled
 * @param  {string} props.onChange
 * @param  {string} props.error
 * @param  {string} props.description
 * @param  {string} props.marginBottom
* @param  {boolean} props.isClearable
 * @param  {string} props.css - css class names
 */
export function ADSelectCountry({
	id, name, label, required = false, showCountryCode = false, showFlag = false,
	value, disabled = false, onChange, error, description, css = "", marginBottom = "mb-3", isClearable = false
}) {

	const data = []
	countries.forEach(e => {
		let val = e.Name
		if (showCountryCode) {
			val = val + " " + e.PhoneCode
		}
		if (showFlag) {
			val = val + " " + e.Emoji
		}
		data.push({
			value: e.ISO2,
			label: val,
		})
	});


	const [isLoading, setIsLoading] = useState(false)
	const [options, setOptions] = useState(data)

	return (
		<React.Fragment>
			<div className={marginBottom}>
				{label &&
					<ADLabel htmlFor={id} css="mb-1"> {label} {!required && <small className="text-muted fw-normal">- Optional</small>}</ADLabel>
				}
				<Select
					id={id}
					name={name}
					// components={{ Menu: CustomMenu }}
					isClearable={isClearable}
					required={required}
					isLoading={isLoading}
					isDisabled={disabled}
					onChange={onChange}
					// onCreateOption={handleCategoryCreate}
					options={options}
					value={options.find(obj => obj.value === value)}
					// value={options.find((obj) => { obj.options.find(o => o.value == props.value) })}
					// value={currentValue}
					className={`${css}`}
					styles={{
						control: (styles) => ({
							...styles, 
							...(error && { borderColor: 'red' }),
							// ...(styles.isFocused && { borderColor: 'yellow' }),
							// Overwrittes the different states of border
							borderColor: styles.isFocused ? "#324BC5" : "#ced4da",
							// Removes weird border around container
							boxShadow: styles.isFocused ? null : null,
							"&:hover": {
								// Overwrittes the different states of border
								//borderColor: "#99a5e2"
							},
							"&:focus": {
								// Overwrittes the different states of border
								borderColor: "#324BC5"
							},
						}),
					}}
					theme={theme => ({
						...theme,
						borderRadius: 2,
						border: '1px solid #ced4da',
						colors: {
							...theme.colors,
							primary25: '#EBEFFF',
							primary: '#324BC5',
						},
					})}
				/>
				{error &&
					<small className="form-text text-danger">{error.message}</small>
				}
				{description &&
					<small className="form-text text-muted">{description}</small>
				}
			</div>
		</React.Fragment>
	)
}

/**
 * Engineer Select dropdown
 * @param props
 * @param  {string} props.id
 * @param  {string} props.name
 * @param  {string} props.label
 * @param  {boolean} props.required
 * @param  {string} props.value
 * @param  {boolean} props.disabled
 * @param  {string} props.onChange
 * @param  {string} props.error
 * @param  {string} props.description
 * @param  {string} props.marginBottom
* @param  {boolean} props.isClearable
 * @param  {string} props.css - css class names
 */
export function ADSelectEngineer({
	id, name, label, required = false, 
	value, disabled = false, onChange, error, description, css = "", marginBottom = "mb-3", isClearable = false
}) {


	const [isLoading, setIsLoading] = useState(false)
	const [options, setOptions] = useState([])
	let organizationID = useOrganizationID();

	// Get Jobs from remote
	const list = async (size, skip, search, accountType) => {
		setIsLoading(true)
		await ListAccountAPI(organizationID, size, skip, search, accountType).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				var obj = []
				data.data.forEach(e => {
					obj.push({ value: e.id, label: e.name})
				});
				setOptions(obj)
			}
		}).catch((err) => {
			
		}).finally(() => {
			setIsLoading(false)
		})
	}

	// Trigger on page laod
	useEffect(() => {
		list(500, "", "", constAccountTypeEngineer)
	}, [])

	return (
		<React.Fragment>
			<div className={marginBottom}>
				{label &&
					<ADLabel htmlFor={id} css="mb-1"> {label} {!required && <small className="text-muted fw-normal">- Optional</small>}</ADLabel>
				}
				<Select
					id={id}
					name={name}
					// components={{ Menu: CustomMenu }}
					isClearable={isClearable}
					required={required}
					isLoading={isLoading}
					isDisabled={disabled}
					onChange={onChange}
					// onCreateOption={handleCategoryCreate}
					options={options}
					value={options.find(obj => obj.value === value)}
					// value={options.find((obj) => { obj.options.find(o => o.value == props.value) })}
					// value={currentValue}
					className={`${css}`}
					styles={{
						control: (styles) => ({
							...styles, 
							...(error && { borderColor: 'red' }),
							// ...(styles.isFocused && { borderColor: 'yellow' }),
							// Overwrittes the different states of border
							borderColor: styles.isFocused ? "#324BC5" : "#ced4da",
							// Removes weird border around container
							boxShadow: styles.isFocused ? null : null,
							"&:hover": {
								// Overwrittes the different states of border
								//borderColor: "#99a5e2"
							},
							"&:focus": {
								// Overwrittes the different states of border
								borderColor: "#324BC5"
							},
						}),
					}}
					theme={theme => ({
						...theme,
						borderRadius: 2,
						border: '1px solid #ced4da',
						colors: {
							...theme.colors,
							primary25: '#EBEFFF',
							primary: '#324BC5',
						},
					})}
				/>
				{error &&
					<small className="form-text text-danger">{error.message}</small>
				}
				{description &&
					<small className="form-text text-muted">{description}</small>
				}
			</div>
		</React.Fragment>
	)
}