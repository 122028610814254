import React from 'react'

/**
 * Card
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADCard({ css = "", ...props }) {
	// const { css, ...attr } = props
	return (
		<React.Fragment>
			{/* add mt-4 if required */}
			<div className={`app-card mb-4 shadow-sm ${css}`}>
				{props.children}
			</div>
			{/* <div className={`app-card app-card-account shadow-sm d-flex flex-column align-items-start ${css}`}>
				{props.children}
			</div> */}
			{/* <div className={`app-card app-card-settings shadow-sm p-4 mb-4 ${css}`}>
				{props.children}
			</div> */}
		</React.Fragment>
	)
}

/**
 * Card Body
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADCardBody({ css = "", ...props }) {
	// const { css, ...attr } = props
	return (
		<React.Fragment>
			<div className={`app-card-body p-3 ${css}`}>
				{props.children}
			</div>
		</React.Fragment>
	)
}

/**
 * Card Title can be used for its simplicity, instead of ADCardHeader
 * @param props
 * @param  {string} props.css - css class name
 * @param  {string} props.description - Body description
 */
export function ADCardTitle({ css ="", description, ...props }) {
	// const { css, ...attr } = props
	return (
		<React.Fragment>
			<label className={`text-uppercase fw-bold mb-0 ${css}`}>
				{props.children}
			</label>
			<div className="form-text text-muted mb-2">{description}</div>
		</React.Fragment>
	)
}

/**
 * Card Footer
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADCardFooter({ css = "", ...props }) {
	return (
		<React.Fragment>
			<div className={`app-card-footer p-3 pt-0 mt-auto ${css}`}>
				{props.children}
			</div>
		</React.Fragment>
	)
}

/**
 * Card Header
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADCardHeader({ icon, title, description, actions, css = "", ...props }) {
	return (
		<React.Fragment>
			{/* <div className={`app-card-header ${css}`}>
				{props.children}
			</div> */}
			{/* <div className="app-card-header p-3 border-bottom-0">
				<div className="row align-items-center gx-3">
					<div className="col-auto">
						<div className="app-icon-holder">
							{icon}
						</div>
					</div>
					<div className="col-auto">
						<h4 className="app-card-title">{title}</h4>
					</div>
				</div>
			</div> */}


			<div className="app-card-header p-3">
				<div className="row justify-content-item align-items-center">
					{icon !== undefined &&
						<div className="col-auto">
							<div className="app-icon-holder">
								{icon}
							</div>
						</div>
					}
					{title !== undefined &&
						<div className="col-auto">
							<h2 className="app-card-title">{title}</h2>
							<div className="form-text text-muted">{description}</div>
						</div>
					}
					{actions !== undefined &&
						<div className="col-auto ms-auto">
							<div className="card-header-action">
								{actions}
							</div>
						</div>
					}
				</div>
			</div>

		</React.Fragment>
	)
}