import React, { Fragment, useReducer } from 'react'
import { CreateUserDemoAPI, ListUserDemoAPI } from '../api/userDemoAPI'
import { constAPISuccess } from '../utils/helper'

export function useUserDemo() {
	
	const actionTypes = {
		OnChange: 'OnChange',
		Loading: 'Loading',
		Create: 'Create',
		List: 'List'
	}

	const userDemoReducer = (state, action) => {
		switch (action.type) {
			case actionTypes.OnChange: {
				return { ...state, [action.name]: action.value, Status: "", Error: "", Loading: false}
			}
			case actionTypes.Create: {
				return { ...state, Data: action.payload, Status: constAPISuccess, Error: "", Loading: false}
			}
			case actionTypes.List: {
				// return { ...state, data: action.payload, Loading:false }
				return { ...state, Data: [...state.Data, ...action.payload] , Status: constAPISuccess, Error: "", Loading: false }
			}
			case actionTypes.Loading: {
				return { ...state, Status: "", Error: "", Loading: true}
			}
			default: {
				throw new Error(`Unhandled type: ${action.type}`)
			}
		}
	}

	const initialState = { Status: "", Data: { Name: "", Job: "" }, Error: "", Loading: false }
	const initialStateList = { Status: "", Data:[], Error: "", Loading: false }
	const [state, dispatch] = useReducer(userDemoReducer, initialState)
	const [stateList, dispatchList] = useReducer(userDemoReducer, initialStateList)

	const handleInputChange = (event) => {
		if (event.target.type == "checkbox") {
			dispatch({
				type: actionTypes.OnChange,
				name: event.target.name, value: event.target.checked ? 1 : -1
			})
		} else {
			dispatch({
				type: actionTypes.OnChange,
				name: event.target.name, value: event.target.value
			})
		}
	};

	const create = () => {
		dispatch({ type: actionTypes.Loading })
		const dataObject = { name: state.Name, job: state.Job }
		CreateUserDemoAPI(dataObject).then((data) => {
			dispatch({
				type: actionTypes.Create,
				payload: { Name: data.name + "test", ID: data.id, Job: data.job, CreatedAt: data.createdAt }
			})
		})
	}

	const list = (page = 1) => {
		dispatchList({ type: actionTypes.Loading })		
		ListUserDemoAPI(page).then((data) => {
			var obj = []			
			data.data.forEach(e => {
				obj.push({ ID: e.id, FirstName: e.first_name, LastName: e.last_name, Avatar: e.avatar })
			});
			
			dispatchList({
				type: actionTypes.List,
				payload: obj.map(e => { return e }),
			})

		}) 
	}

	return [ state, stateList, { create, list, handleInputChange }]
}