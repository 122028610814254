import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateJobAPI, GetJobAPI, GetJobSummaryAPI, ListJobAPI, UpdateJobEngineerAPI, UpdateJobScheduleAPI } from '../api/jobAPI';
import { ADSelectCountry, ADSelectEngineer } from '../components/select';
import { ADButton } from '../components/ui/button';
import { ADCard, ADCardBody, ADCardFooter, ADCardHeader } from '../components/ui/card';
import { SearchIcon, NewIcon, Men1Icon, Man1Icon, DownShortArrowIcon, PersonIcon } from '../components/ui/icons';
import { ADInput } from '../components/ui/input';
import { ADLoading } from '../components/ui/loading';
import { ADNoRecords } from '../components/ui/noRecords';
import { ADPageActions, ADPageHeader, ADPageTitle } from '../components/ui/page';
import { ADTable, ADTableBody, ADTableHead, ADTableTD, ADTableTH, ADTableTR } from '../components/ui/table';
// import { useJob } from '../hooks/useJob';
import { useOrganizationID } from '../hooks/useOrganizationID';
import { useParamSearchTerm } from '../hooks/useParamSearchTerm'
import { useID } from '../hooks/useID';
import { constAPISuccess, constRecordNotFound, getMessage, processError, constAdd, actionTypes, constAPIError, constInput, isNullOrEmpty, getPageSize, formatDate, getDateFormat, getDateTimeFormat, removeTimeZoneZ } from '../utils/helper';
import { jobReducer } from '../reducers/jobReducer'
import { ADAlert } from '../components/ui/alert';
import { ADJobStatus } from '../components/jobStatus';
import { ADLabel } from '../components/ui/label';
import { ADDate } from '../components/ui/date';

export function ListJobPage() {

	//Initialize variables
	let organizationID = useOrganizationID();
	let navigate = useNavigate()
	const initialState = { status: "", data: [], errors: {}, loading: false, size: getPageSize(), skip: 0 }
	const [state, dispatch] = useReducer(jobReducer, initialState)
	let searchTerm = useParamSearchTerm()

	//Dashboard Summary
	const [summaryState, setSummaryState] = useState({})

	// Handle input changes
	const handleInputChange = (event, name = "", control = "") => {
		//For Select dropdown
		if (control === constInput.reactSelect) {
			let val = !isNullOrEmpty(event) ? event.value : null
			dispatch({
				type: actionTypes.onChange,
				name: name, value: val
			})
		}
		//For check box
		else if (event.target.type == constInput.checkbox) {
			dispatch({
				type: actionTypes.onChange,
				name: event.target.name, value: event.target.checked ? 1 : -1
			})
		}
		//For all other input controls
		else {
			dispatch({
				type: actionTypes.onChange,
				name: event.target.name, value: event.target.value
			})
		}
	};

	// Trigger on page laod
	useEffect(() => {
		getJobSummary()
		list(getPageSize(), state.skip, searchTerm)
	}, [])

	// Get Jobs from remote
	const list = async (size, skip, search) => {
		dispatch({ type: actionTypes.loading })
		await ListJobAPI(organizationID, size, skip, search).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				var obj = []
				data.data.forEach(e => {
					obj.push({ id: e.id, number: e.number, name: e.name, status: e.status, engineerAccountName: e.engineerAccountName, totalAmount: e.totalAmount, categoryName: e.categoryName, mobileNumber: e.mobileNumber, scheduledAt: e.scheduledAt })
				});

				dispatch({
					type: actionTypes.list,
					data: obj.map(e => { return e }),
					skip: (skip + size)
				})
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})
	}

	// Get Jobs from remote
	const getJobSummary = async () => {
		await GetJobSummaryAPI(organizationID).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				setSummaryState(data.data)
			}
		}).catch((err) => {

		})
	}

	// Handle New Job button
	const handleNewJob = (event) => {
		navigate(`/${organizationID}/job/create`)
	}

	// Render Jobs into a table
	const ListJob = () => {
		return (
			<Fragment>
				{!state.loading && state.data && state.data.length === 0 &&
					<ADNoRecords />
				}
				{state.data && state.data.length > 0 &&
					<ADTable key="list" css='app-card-table' >
						<ADTableHead>
							<ADTableTR>
								<ADTableTH>#Job ID</ADTableTH>
								<ADTableTH>Name</ADTableTH>
								<ADTableTH>Mobile #</ADTableTH>
								<ADTableTH>Category</ADTableTH>
								<ADTableTH>Status</ADTableTH>
								<ADTableTH>Engineer</ADTableTH>
								<ADTableTH>Scheduled At</ADTableTH>
								<ADTableTH>Payment</ADTableTH>
							</ADTableTR>
						</ADTableHead>
						<ADTableBody>
							{listJobItem}
						</ADTableBody>
					</ADTable>

				}
				{state.loading &&
					<ADLoading />
				}
			</Fragment>
		)
	}

	// Render a job in a row
	const listJobItem = state.data.map((data, index) => {
		return (
			<ADTableTR key={index}>
				<ADTableTD><a href={`/${organizationID}/job/${data.id}`}>{data.number}</a></ADTableTD>
				<ADTableTD><a href={`/${organizationID}/job/${data.id}`}>{data.name}</a></ADTableTD>
				<ADTableTD>{data.mobileNumber}</ADTableTD>
				<ADTableTD>{data.categoryName}</ADTableTD>
				<ADTableTD> <ADJobStatus status={data.status} /> </ADTableTD>
				<ADTableTD>{data.engineerAccountName}</ADTableTD>
				<ADTableTD>{formatDate(data.scheduledAt)}</ADTableTD>
				<ADTableTD>Rs.{data.totalAmount}</ADTableTD>
			</ADTableTR>
		)
	});

	return (
		<>
			<div className="container-xl">
				<div className="row">

					<div className="col-md-2">
						<ADCard>
							<ADCardBody css="text-center">
								<h5 className='mb-2'><ADJobStatus status={"Booked"} /></h5>
								<h2>{summaryState.booked}</h2>
							</ADCardBody>
						</ADCard>
					</div>

					<div className="col-md-2">
						<ADCard>
							<ADCardBody css="text-center">
								<h5 className='mb-2'><ADJobStatus status={"Assigned"} /></h5>
								<h2>{summaryState.assigned}</h2>
							</ADCardBody>
						</ADCard>
					</div>

					<div className="col-md-2">
						<ADCard>
							<ADCardBody css="text-center">
								<h5 className='mb-2'><ADJobStatus status={"Engineer on the way"} /></h5>
								<h2>{summaryState.engineerOnTheWay}</h2>
							</ADCardBody>
						</ADCard>
					</div>

					<div className="col-md-2">
						<ADCard>
							<ADCardBody css="text-center">
								<h5 className='mb-2'><ADJobStatus status={"Job Completed"} /></h5>
								<h2>{summaryState.jobCompleted}</h2>
							</ADCardBody>
						</ADCard>
					</div>

					<div className="col-md-2">
						<ADCard>
							<ADCardBody css="text-center">
								<h5 className='mb-2'><ADJobStatus status={"Job in Progress"} /></h5>
								<h2>{summaryState.jobInProgress}</h2>
							</ADCardBody>
						</ADCard>
					</div>

					<div className="col-md-2">
						<ADCard>
							<ADCardBody css="text-center">
								<h5 className='mb-2'><ADJobStatus status={"Job Cancelled"} /></h5>
								<h2>{summaryState.jobCancelled}</h2>
							</ADCardBody>
						</ADCard>
					</div>

				</div>
				<ADPageHeader>
					<ADPageTitle>Jobs</ADPageTitle>
					<ADPageActions>
						<div className="col-auto">
							<form>
								<ADInput
									id="search"
									name="search"
									required={true}
									placeholder="Search Name, Mobile"
									marginBottom="mb-0"
									action={
										<ADButton
											key="search"
											isProcessing={false}
											style="light"
											type="submit"
											prefixIcon={<SearchIcon />}
										/>
									}
								/>
							</form>
						</div>
						{/* <div className="col-auto">
							<ADButton value="New Job" prefixIcon={<NewIcon />} css="mb-0" onClick={handleNewJob} />
						</div> */}
					</ADPageActions>
				</ADPageHeader>

				<div className="row">
					<div className="col-12">
						<ADCard>
							<ADCardBody>
								<ListJob />
							</ADCardBody>
							<ADCardFooter>
								<div className='d-flex justify-content-center'>
									<ADButton
										onClick={(e) => { list(getPageSize(), state.skip, searchTerm) }}
										style="light rounded-pill"
										isProcessing={state.loading}
										value="Load More"
										suffixIcon={<DownShortArrowIcon />}
									/>
								</div>
							</ADCardFooter>
						</ADCard>
					</div>
				</div>
			</div>
		</>
	)
}

export function ViewJobPage() {

	let organizationID = useOrganizationID();
	let id = useID();
	let navigate = useNavigate()
	const [error, setError] = useState({})

	const initialState = { status: "", data: { items: [] }, errors: null, loading: false }
	const [state, dispatch] = useReducer(jobReducer, initialState)

	const handleInputChange = (event, name = "", control = "") => {
		if (control === constInput.reactSelect) { //For Select dropdown
			let val = !isNullOrEmpty(event) ? event.value : null
			dispatch({ type: actionTypes.onChange, name: name, value: val })
		} else if (event.target.type == constInput.checkbox) { //For check box
			dispatch({ type: actionTypes.onChange, name: event.target.name, value: event.target.checked ? 1 : -1 })
		} else { //For all other input controls
			dispatch({ type: actionTypes.onChange, name: event.target.name, value: event.target.value })
		}
	};

	const handleCancelClick = (e) => {
		navigate(`/${organizationID}/job`)
	}

	// Trigger on page laod
	useEffect(() => {
		view()
	}, [])

	//View a job
	const view = async () => {
		dispatch({ type: actionTypes.loading })
		await GetJobAPI(organizationID, id).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				let e = data.data
				dispatch({
					type: actionTypes.success,
					data: {
						id: e.id,
						accountID: e.accountID,
						number: e.number,
						address1: e.address1,
						address2: e.address2,
						address3: e.address3,
						city: e.city,
						state: e.state,
						zipcode: e.zipcode,
						createdAt: e.createdAt,
						updatedAt: e.updatedAt,
						status: e.status,
						scheduledAt: e.scheduledAt,
						totalAmount: e.totalAmount,
						couponCode: e.couponCode,
						couponAmount: e.couponAmount,
						voucherCode: e.voucherCode,
						voucherAmount: e.voucherAmount,
						engineerAccountID: e.engineerAccountID,
						isPaymentReceived: e.isPaymentReceived,
						engineerRating: e.engineerRating,
						companyRating: e.companyRating,
						startJobAt: e.startJobAt,
						stopJobAt: e.stopJobAt,
						startJobOtp: e.startJobOtp,
						stopJobOtp: e.stopJobOtp,
						organizationID: e.organizationID,
						name: e.name,
						mobileNumber: e.mobileNumber,
						engineerAccountName: e.engineerAccountName,
						categoryName: e.categoryName,
						items: e.items,
					}
				})
				// toast.success(getMessage(constAdd, 'Job'));
				// navigate(`/${organizationID}/job`)
			} //on error
			else if (data && data.status === constAPIError) {
				const [isRecordNotFound, err] = processError(data.errors)
				if (isRecordNotFound) {
					toast.error(getMessage(constRecordNotFound))
					navigate(`/${organizationID}/job`)
				} else {
					dispatch({
						type: actionTypes.error,
						errors: err
					})
				}
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})

	}

	// Render a job in a row
	const listJobItem = state.data.items.map((data, index) => {
		return (
			<ADTableTR key={index}>
				<ADTableTD>{data.name}</ADTableTD>
				<ADTableTD>{data.time}</ADTableTD>
				<ADTableTD>{data.cost}</ADTableTD>
			</ADTableTR>
		)
	});

	//Update schedule
	const updateSchedule = async () => {
		dispatch({ type: actionTypes.loading })
		// const payload = { } //Send empty payload
		await UpdateJobScheduleAPI(organizationID, id, state.data.scheduledAt).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				dispatch({
					type: actionTypes.success,
					data: state.data,
				})
				toast.success("Scheduled Time updated");
				// navigate(`/${organizationID}/job`)
			} //on error
			else if (data && data.status === constAPIError) {
				const [isRecordNotFound, err] = processError(data.errors)
				if (isRecordNotFound) {
					toast.error(getMessage(constRecordNotFound))
					// navigate(`/${organizationID}/job`)
				} else {
					dispatch({
						type: actionTypes.error,
						errors: err
					})
				}
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})
	}

	//Update schedule
	const updateEngineer = async () => {
		dispatch({ type: actionTypes.loading })
		await UpdateJobEngineerAPI(organizationID, id, state.data.engineerAccountID).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				dispatch({
					type: actionTypes.success,
					data: state.data,
				})
				toast.success("Engineer Assigned");
				// navigate(`/${organizationID}/job`)
			} //on error
			else if (data && data.status === constAPIError) {
				const [isRecordNotFound, err] = processError(data.errors)
				if (isRecordNotFound) {
					toast.error(getMessage(constRecordNotFound))
					// navigate(`/${organizationID}/job`)
				} else {
					dispatch({
						type: actionTypes.error,
						errors: err
					})
				}
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})
	}

	const handleScheduleFormSubmit = (e) => {
		e.preventDefault()
		// setError({})
		updateSchedule()
	}

	const handleEngineerFormSubmit = (e) => {
		e.preventDefault()
		// setError({})
		updateEngineer()
	}

	return (
		<>
			<Fragment>
				{state.loading &&
					<ADLoading />
				}

				{state && !state.loading && state.data &&
					<React.Fragment>
						<ADAlert error={state.errors} />
						<div className="container-xl">

							<ADPageHeader>
								<ADPageTitle><span className="pe-2">Job #{state.data.number}</span><ADJobStatus status={state.data.status} /></ADPageTitle>
							</ADPageHeader>


							<div className="row">
								<div className="col-lg-6">
									<ADCard>
										<ADCardHeader
											title="Contact Information"
											icon={<PersonIcon />}
										/>

										<ADCardBody>
											{!isNullOrEmpty(state.data.name) &&
												<ADLabel>{state.data.name}</ADLabel>
											}

											{!isNullOrEmpty(state.data.address1) &&
												<div>{state.data.address1}</div>
											}
											{!isNullOrEmpty(state.data.address2) &&
												<div>{state.data.address2}</div>
											}
											{!isNullOrEmpty(state.data.address3) &&
												<div>{state.data.address3}</div>
											}
											{!isNullOrEmpty(state.data.city) &&
												<div>{state.data.city}</div>
											}
											{!isNullOrEmpty(state.data.state) &&
												<div>{state.data.state}</div>
											}
											{!isNullOrEmpty(state.data.zipcode) &&
												<div>{state.data.zipcode}</div>
											}

											{!isNullOrEmpty(state.data.mobileNumber) &&
												<ADLabel>{state.data.mobileNumber}</ADLabel>
											}



										</ADCardBody>
									</ADCard>
								</div>

								<div className="col-lg-6">
									<ADCard>
										<ADCardBody>
											<form onSubmit={handleScheduleFormSubmit} encType="multipart/form-data">
												<div className="row justify-content-between">
													<div className="col-9">
														<ADDate
															id="scheduledAt"
															name="scheduledAt"
															label="Scheduled Date & Time"
															required={true}
															value={removeTimeZoneZ(state.data.scheduledAt)}
															css={'bg-white'}
															options={{
																enableTime: true,
																dateFormat: getDateTimeFormat(),
																time_24hr: true
															}}
															onChange={(selectedDates, dateStr, instance) => {
																dispatch({ type: actionTypes.onChange, name: "scheduledAt", value: dateStr })
																//setObject({ ...object, ["OrderDate"]: new Date(dateStr) });
															}}
														/>
													</div>
													<div className="col-auto d-flex align-items-center">
														<ADButton
															type='submit'
															isProcessing={state.loading}
															value="Update"
															size="lg"
															css='w-100'
														/>
													</div>
												</div>
											</form>
											<hr />
											<form onSubmit={handleEngineerFormSubmit} encType="multipart/form-data">
												<div className="row justify-content-between">
													<div className="col-9">
														<ADSelectEngineer
															required={true}
															id="engineerAccountID"
															name="engineerAccountID"
															label={"Engineer"}
															showCountryCode={true}
															isClearable={false}
															value={state.data.engineerAccountID}
															onChange={(e) => { handleInputChange(e, "engineerAccountID", constInput.reactSelect) }}
															error={state && state.errors && state.errors.engineerAccountID}
														/>
													</div>
													<div className="col-auto d-flex align-items-center">
														<ADButton
															type='submit'
															isProcessing={state.loading}
															value="Assign"
															size="lg"
														/>
													</div>
												</div>
											</form>
											<hr />

											<div className="row justify-content-between">
												<div className="col-9">
													<ADInput
														id="otp"
														name="otp"
														type="text"
														label="Cancel OTP"
														required={true}
														placeholder="OTP"
														maxLength={50}
														disabled={true}
														// value={state.data.address2}
														// error={state && state.errors && state.errors.name}
														onChange={handleInputChange}
													/>
												</div>
												<div className="col-auto d-flex align-items-center">
													<ADButton
														type='submit'
														isProcessing={state.loading}
														value="Submit"
														size="lg"
														css='w-100'
													/>
												</div>
											</div>

										</ADCardBody>
									</ADCard>
								</div>
							</div>

							<div className="row">
								<div className="col-6 col-lg-6">
									<ADCard>
										<ADCardHeader
											title={`${state.data.categoryName} Services`}
											icon={<PersonIcon />}
										/>

										<ADCardBody>
											{state.data.items && state.data.items.length > 0 &&
												<Fragment>
													<ADTable key="list" css='app-card-table' >
														<ADTableHead>
															<ADTableTR>
																<ADTableTH>Name</ADTableTH>
																<ADTableTH>Duration (mins)</ADTableTH>
																<ADTableTH>Amount (Rs.)</ADTableTH>
															</ADTableTR>
														</ADTableHead>
														<ADTableBody>
															{listJobItem}
															<ADTableTR key="voucher">
																<td colSpan={2}>Voucher</td>
																<ADTableTD>-{state.data.voucherAmount}</ADTableTD>
															</ADTableTR>
															<ADTableTR key="coupon">
																<td colSpan={2}>Coupon</td>
																<ADTableTD>-{state.data.couponAmount}</ADTableTD>
															</ADTableTR>
															<ADTableTR key="total">
																<td colSpan={2}><strong>Total Amount</strong></td>
																<ADTableTD><strong>{state.data.totalAmount}</strong></ADTableTD>
															</ADTableTR>
														</ADTableBody>
													</ADTable>
												</Fragment>

											}
										</ADCardBody>
									</ADCard>
								</div>
							</div>

							{!isNullOrEmpty(state.data.engineerRating) &&
								<div className="row">
									<div className="col-6 col-lg-6">
										<ADCard>
											<ADCardHeader
												title="Rating"
												icon={<PersonIcon />}
											/>

											<ADCardBody>
												<ADLabel>Engineer Rating : {state.data.engineerRating}</ADLabel> <br />
												<ADLabel>Company Rating : {state.data.companyRating}</ADLabel>
											</ADCardBody>
										</ADCard>
									</div>
								</div>
							}

							{/* <div className="row">
								<div className="col-12">
									<div className="row justify-content-between">
										<div className="col-auto">
											<ADButton
												style="light"
												isProcessing={false}
												value="Cancel"
												size="lg"
												onClick={handleCancelClick}
											/>
										</div>
										<div className="col-auto">
											<ADButton
												type='submit'
												isProcessing={state.loading}
												value="Save"
												size="lg"
											/>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</React.Fragment>
				}
			</Fragment>
		</>
	)
}


//NOTE: CreateJobPage is not used
export function CreateJobPage() {

	let organizationID = useOrganizationID();
	let navigate = useNavigate()
	const [error, setError] = useState({})

	const initialState = { status: "", data: { email: "", mobileCountryCode: "", mobileNumber: "", name: "", source: "" }, errors: null, loading: false }
	const [state, dispatch] = useReducer(jobReducer, initialState)

	const handleInputChange = (event, name = "", control = "") => {
		if (control === constInput.reactSelect) { //For Select dropdown
			let val = !isNullOrEmpty(event) ? event.value : null
			dispatch({ type: actionTypes.onChange, name: name, value: val })
		} else if (event.target.type == constInput.checkbox) { //For check box
			dispatch({ type: actionTypes.onChange, name: event.target.name, value: event.target.checked ? 1 : -1 })
		} else { //For all other input controls
			dispatch({ type: actionTypes.onChange, name: event.target.name, value: event.target.value })
		}
	};

	const handleCancelClick = (e) => {
		navigate(`/${organizationID}/job`)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault()
		// setError({})
		create()
	}

	const create = async () => {
		dispatch({ type: actionTypes.loading })
		const payload = { email: state.data.email, mobileCountryCode: state.data.mobileCountryCode, mobileNumber: state.data.mobileNumber, name: state.data.name }
		await CreateJobAPI(organizationID, payload).then((data) => {
			//on success
			if (data && data.status === constAPISuccess) {
				dispatch({
					type: actionTypes.success,
					data: { ID: data.id }
				})
				toast.success(getMessage(constAdd, 'Job'));
				navigate(`/${organizationID}/job`)
			} //on error
			else if (data && data.status === constAPIError) {
				const [isRecordNotFound, err] = processError(data.errors)
				if (isRecordNotFound) {
					toast.error(getMessage(constRecordNotFound))
					navigate(`/${organizationID}/job`)
				} else {
					dispatch({
						type: actionTypes.error,
						errors: err
					})
				}
			}
		}).catch((err) => {
			dispatch({
				type: actionTypes.error,
				errors: null
			})
		})
	}

	return (
		<>
			<ADAlert error={state && state.errors} />
			<div className="container-xl">
				<ADPageHeader>
					<ADPageTitle>New Job</ADPageTitle>
				</ADPageHeader>

				<form onSubmit={handleFormSubmit} encType="multipart/form-data">
					<div className="row">
						<div className="col-12 col-lg-8">
							<ADCard>
								<ADCardBody>
									<ADInput
										id="name"
										name="name"
										type="text"
										label="Name"
										required={true}
										placeholder="Ex. Harry"
										maxLength={50}
										value={state.data.name}
										error={state && state.errors && state.errors.name}
										onChange={handleInputChange}
									/>

									<ADInput
										id="email"
										name="email"
										type="email"
										label="Email"
										placeholder="yourname@gmail.com"
										maxLength={50}
										value={state.data.email}
										error={state && state.errors && state.errors.email}
										onChange={handleInputChange}
									/>
									<div className="row">
										<div className="col-6 col-sm-4">
											<ADSelectCountry
												id="mobileCountryCode"
												name="mobileCountryCode"
												label={"Country Code"}
												showCountryCode={true}
												isClearable={true}
												value={state.data.mobileCountryCode}
												onChange={(e) => { handleInputChange(e, "mobileCountryCode", constInput.reactSelect) }}
												error={state && state.errors && state.errors.mobileCountryCode}
											/>
										</div>
										<div className="col-6 col-sm-8">
											<ADInput
												id="mobileNumber"
												name="mobileNumber"
												type="number"
												label="Mobile Number"
												maxLength={11}
												value={state.data.mobileNumber}
												error={state && state.errors && state.errors.mobileNumber}
												onChange={handleInputChange}
											/>
										</div>
									</div>
								</ADCardBody>
							</ADCard>
						</div>
					</div>

					<div className="row">
						<div className="col-8">
							<div className="row justify-content-between">
								<div className="col-auto">
									<ADButton
										style="light"
										isProcessing={false}
										value="Cancel"
										size="lg"
										onClick={handleCancelClick}
									/>
								</div>
								<div className="col-auto">
									<ADButton
										type='submit'
										isProcessing={state.loading}
										value="Save"
										size="lg"
									/>
								</div>
							</div>
						</div>
					</div>

				</form>
			</div>
		</>
	)
}
