import React from 'react'

/**
 * Table
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADTable({ css = "", ...props }) {
	return (
		<React.Fragment>
			<div className="table-responsive">
				<table className={`table app-table-hover mb-0 text-left ${css}`}>
					{props.children}
				</table>
			</div>
		</React.Fragment>
	)
}

/**
 * Table Head - <thead>
 * @param props
 */
 export function ADTableHead({ ...props }) {
	return (
		<React.Fragment>
			<thead>
					{props.children}
			</thead>
		</React.Fragment>
	)
 }

 /**
 * Table body - <tbody>
 * @param props
 */
export function ADTableBody({ ...props }) {
	return (
		<React.Fragment>
			<tbody>
					{props.children}
			</tbody>
		</React.Fragment>
	)
}

/**
 * Table Row - <tr>
 * @param props
 * @param  {string} props.css - css class name
 */
export function ADTableTR({ css = "", ...props }) {
	return (
		<React.Fragment>
			<tr className={`${css}`}>
				{props.children}
			</tr>
		</React.Fragment>
	)
}

/**
 * Table Head - <th>
 * @param props
 * @param  {string} props.css - css class name
 */
 export function ADTableTH({ css = "", ...props }) {
	return (
		<React.Fragment>
			<th className={`${css}`}>
				{props.children}
			</th>
		</React.Fragment>
	)
}

/**
 * Table Data - <td>
 * @param props
 * @param  {string} props.css - css class name
 */
 export function ADTableTD({ css = "", ...props }) {
	return (
		<React.Fragment>
			<td className={`${css}`}>
				{props.children}
			</td>
		</React.Fragment>
	)
}