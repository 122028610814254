import Server from './server'
import { processException } from '../utils/helper'
import { useOrganizationID } from '../hooks/useOrganizationID';

export async function ListAccountAPI(organizationID, size, skip, search = "", accountType = "") {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().get(`/account?organizationID=${organizationID}&size=${size}&skip=${skip}&search=${search}&accountType=${accountType}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

// export async function GetJobAPI(organizationID, id) {
// 	// let organizationID = useOrganizationID()
// 	try {
// 		const response = await Server().get(`/job/${id}?organizationID=${organizationID}`);
// 		return response.data;
// 	} catch (error) {
// 		processException(error)
// 		throw new Error(error)
// 	}
// }

// export async function CreateJobAPI(organizationID, data) {
// 	// let organizationID = useOrganizationID()
// 	try {
// 		const response = await Server().post(`/job?organizationID=${organizationID}`, data);
// 		return response.data;
// 	} catch (error) {
// 		processException(error)
// 		throw new Error(error)
// 	}
// }

// export async function UpdateJobAPI(organizationID, id, data) {
// 	// let organizationID = useOrganizationID()
// 	try {
// 		const response = await Server().put(`/job/${id}?organizationID=${organizationID}`, data);
// 		return response.data;
// 	} catch (error) {
// 		processException(error)
// 		throw new Error(error)
// 	}
// }

// export async function DeleteJobAPI(organizationID, id) {
// 	// let organizationID = useOrganizationID()
// 	try {
// 		const response = await Server().delete(`/job/${id}?organizationID=${organizationID}`);
// 		return response.data;
// 	} catch (error) {
// 		processException(error)
// 		throw new Error(error)
// 	}
// }