import Server from './server'
import { processException } from '../utils/helper'
import { useOrganizationID } from '../hooks/useOrganizationID';

let organizationID = "9da71a27-fdd4-42bf-8181-bdbbb9fb487b"

export async function ListContactAPI(organizationID, size, skip, search = "") {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().get(`/contact?organizationID=${organizationID}&size=${size}&skip=${skip}&search=${search}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function GetContactAPI(organizationID, id) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().get(`/contact/${id}?organizationID=${organizationID}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function CreateContactAPI(organizationID, data) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().post(`/contact?organizationID=${organizationID}`, data);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function UpdateContactAPI(organizationID, id, data) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().put(`/contact/${id}?organizationID=${organizationID}`, data);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}

export async function DeleteContactAPI(organizationID, id) {
	// let organizationID = useOrganizationID()
	try {
		const response = await Server().delete(`/contact/${id}?organizationID=${organizationID}`);
		return response.data;
	} catch (error) {
		processException(error)
		throw new Error(error)
	}
}