import React, { useState } from "react"
import Select from "react-select"
import countries from "../data/countries.json"
import { isNullOrEmpty } from "../utils/helper"
import { Female1Icon, Female2Icon, Man1Icon, Man2Icon } from "./ui/icons"
import { ADLabel } from "./ui/label"

export function ADRandomPersonIcon() {

	const items = ["man1", "man2", "female1", "female2"]
	var item = items[Math.floor(Math.random()*items.length)];
	var component = <Man1Icon />
	if (item === "man2") {
		component = <Man2Icon />
	} else if (item === "female1") {
		component = <Female1Icon />
	} else if (item === "female2") {
		component = <Female2Icon />
	}
	return (
		<React.Fragment>
			{component}
			</React.Fragment>
	)
}