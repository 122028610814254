import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { ConstKVPNameRole, ConstKVPNameToken, getKVP, getToken, isNullOrEmpty, setKVP } from './utils/helper';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
	Navigate,
	useNavigate,
	Routes,
	BrowserRouter
} from "react-router-dom";
import ADHeader from './components/header';
import AboutPage from './pages/about';
import AllScreens from './AllScreens';
import { CreateContactPage, ListContactPage } from './pages/contact';
import { AD404Error } from './components/ui/404'
import 'react-toastify/dist/ReactToastify.min.css';
import { CreateContactPage2, ListContactPage2 } from './pages/contact2';
import LoginPage from './pages/login';
import DashboardPage from './pages/dashboard';
import { ListJobPage, ViewJobPage } from './pages/job';

/**
 * 	Init used to get the token from the query string parameter `t`. If token is not found then try to fetch from KVP. Also redirect user based on the token.
 **/
const Init = () => {

	const queryString = require('query-string');
	const parsed = queryString.parse(window.location.search);
	var isTokenAvailable = false
	// Get `t` from query params
	if (parsed.t === undefined || parsed.t === "") {
		isTokenAvailable = false
	} else {
		isTokenAvailable = true
		setKVP(ConstKVPNameToken, parsed.t)
	}

	//If no token in params. Get from local storage
	if (isTokenAvailable === false) {
		let sot = getToken()
		if (sot != "") {
			isTokenAvailable = true
		}
	}
	return (
		<>
			{isTokenAvailable && <Route element={<Navigate replace to="/config" />} />}
			{isTokenAvailable === false && <Route element={<Navigate replace to="/login" />} />}
		</>
	)
}

/**
 * Once successfully initializing the application get the application wide settings from the server and set in KVP
 */
const LoadConfig = () => {
	const navigate = useNavigate();
	//TODO: Fix this once settigns are updated
	// const getConfig = async () => {
	//   try {
	//     const response = await Server(getToken()).get('/store/settings');
	//     if (response.data.status == "success") {
	//       const data = response.data.data
	//       setCurrency(data.currency_symbol)
	//     }
	//   } catch (error) {
	//     processException(error)
	//   }
	// }  
	useEffect(() => {
		// getConfig()
		navigate(`/item`);
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row" style={{ height: "100vh" }}>
					<div className="col-md-12 text-center my-auto">
						<img src="assets/images/loading_dark.svg" style={{ height: "50px" }} /><p>Loading ...</p>
					</div>
				</div>
			</div>
		</>
	)
}

/**
 * Generic layout used for auth
 */
const Layout = (props) => {
	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				closeOnClick={true}
				pauseOnHover={true}
				draggable={false}
			/>
			{props.children}
		</>
	)
}

/**
 * Layout used post login with Nav bar and Sidebar.
 */
const AdminLayout = (props) => {
	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				closeOnClick={true}
				pauseOnHover={true}
				draggable={false}
			/>
			<ADHeader />
			{/* <SideBar /> */}
			<div className="app-wrapper">
				<div className="app-content pt-3 p-md-3 p-lg-4">
					{/* <div className="container-fluid"> */}
					{props.children}
					{/* </div> */}
				</div>
			</div>
		</>
	)
}

//https://medium.com/javascript-in-plain-english/simple-guide-for-layouts-in-react-router-e32b26c12cee
//https://medium.com/javascript-in-plain-english/how-to-build-reusable-layouts-in-react-js-daf8adcbca79
/**
 * Use to wrap the page component and check for the permission
 */
function AuthWrapper(props) {
	//TODO: Fix roles and permissions
	//TODO: Check for organizationID query parameter. If doesn't exists then ask them to choose the organization.
	let name = props.name
	// let isPrivate =  props.isPrivate //(isPrivate == undefined) ? true : isPrivate
	// const token = getToken()

	// Check if token exists
	// if (isPrivate === true && isNullOrEmpty(token)) {
	// 	return <Navigate replace to="/login" />
	// }

	// check if route is restricted by role
	// if (roles && roles.indexOf(getKVP(ConstKVPNameRole).toString()) === -1) {
	// 	// role not authorised so redirect to home page
	// 	toast.error("❌ Permission denied.")
	// 	return <Navigate replace to="/dashboard" />
	// }

	return props.children;
}

export default function App() {
	return (
		<Router>
			<Routes>

				{/*
					###
					# Login
					### 
				*/}
				<Route exact path="/" element={
					<AuthWrapper name="login">
						<Layout>
							<LoginPage />
						</Layout>
					</AuthWrapper>
				} />
			
				{/*
					###
					# Welcome
					### 
				*/}
				<Route exact path=":organizationID/dashboard" element={
					<AuthWrapper name="dashboard">
						<AdminLayout>
							<DashboardPage />
						</AdminLayout>
					</AuthWrapper>
				} />

				{/*
					###
					# Jobs
					### 
				*/}
				<Route exact path=":organizationID/job" element={
					<AuthWrapper name="job">
						<AdminLayout>
							<ListJobPage />
						</AdminLayout>
					</AuthWrapper>
				} />

				<Route exact path=":organizationID/job/:id" element={
					<AuthWrapper name="job">
						<AdminLayout>
							<ViewJobPage />
						</AdminLayout>
					</AuthWrapper>
				} />

				{/*
					###
					# Contacts
					### 
				*/}
				<Route exact path=":organizationID/contact" element={
					<AuthWrapper name="contact">
						<AdminLayout>
							<ListContactPage />
						</AdminLayout>
					</AuthWrapper>
				} />
				<Route exact path=":organizationID/contact/create" element={
					<AuthWrapper name="contact">
						<AdminLayout>
							<CreateContactPage />
						</AdminLayout>
					</AuthWrapper>
				} />
				{/*
					###
					# Contacts
					### 
				*/}
				<Route exact path=":organizationID/contact2" element={
					<AuthWrapper name="contact">
						<AdminLayout>
							<ListContactPage2 />
						</AdminLayout>
					</AuthWrapper>
				} />
				<Route exact path=":organizationID/contact2/create" element={
					<AuthWrapper name="contact">
						<AdminLayout>
							<CreateContactPage2 />
						</AdminLayout>
					</AuthWrapper>
				} />
				
				<Route exact path=":organizationID/screen" element={
					<AuthWrapper name="contact">
						<AdminLayout>
							<AllScreens />
						</AdminLayout>
					</AuthWrapper>
				} />
				<Route exact path="/user" element={
					<AuthWrapper>
						<AdminLayout>
							<AboutPage />
						</AdminLayout>
					</AuthWrapper>
				} />
				<Route
					path="*"
					element={
						<Layout>
							<AD404Error />
						</Layout>
					}
				/>
			</Routes>
		</Router>
	)
}