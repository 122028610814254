import Server from './server'
import { processException } from '../utils/helper'

export async function ListUserDemoAPI(page) {
	try {
		const response = await Server().get(`/users?page=${page}`);
		return response.data;		
	} catch (error) {
		processException(error)
	}	
}

export async function GetUserAPI(id) {
	try {
		const response = await Server().get(`/account/${id}`);
		return response.data;		
	} catch (error) {
		processException(error)
	}
}

export async function CreateUserDemoAPI(data) {
	try {
		const response = await Server().post(`/users`, data);
		return response.data;		
	} catch (error) {
		processException(error)
	}
}

export async function UpdateUserAPI(id, data) {	
	try {
		const response = await Server().put(`/account/${id}`, data);
		return response.data;		
	} catch (error) {
		processException(error)
	}
}

export async function DeleteUserAPI(id) {	
	try {
		const response = await Server().delete(`/auth/${id}`);
		return response.data;		
	} catch (error) {
		processException(error)
	}
}