import { constAPIError, constAPISuccess, actionTypes, isNullOrEmpty } from '../utils/helper'

export const contactReducer = (state, action) => {
	switch (action.type) {
		case actionTypes.onChange: {
			return { ...state, data: { ...state.data, [action.name]: action.value }, status: ""}
		}
		case actionTypes.success: {
			return { ...state, status: constAPISuccess, errors: {}, loading: false}
		}
		case actionTypes.error: {
			let err = isNullOrEmpty(action.errors) ? {} : action.errors
			return { ...state, status: constAPIError, errors: err, loading: false}
		}
		case actionTypes.list: {
			// return { ...state, data: action.payload, Loading:false }
			return { ...state, data: [...state.data, ...action.data] , status: constAPISuccess, errors: {}, loading: false, skip: action.skip }
		}
		case actionTypes.loading: {
			return { ...state, status: "", errors: {}, loading: true}
		}
		default: {
			throw new Error(`Unhandled type: ${action.type}`)
		}
	}
}