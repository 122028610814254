import React, { Fragment } from 'react'

/**
* Switch
* @param props
* @param  {string} param.id
* @param  {string} param.name
* @param  {string} param.label
* @param  {string} param.placeholder
* @param  {string} param.onChange
* @param  {boolean} param.checked
* @param  {string} param.required
* @param  {boolean} param.disabled
* @param  {string} param.css - css class names
* @param  {string} param.error - Error message to be shown
*/
 export function ADSwitch ({ id, name, label, placeholder, onChange, checked, required=false, disabled, css, error}) {
	// const { label, placeholder, , ...attr } = props
	return (
		<Fragment>
			<div className="form-check form-switch mb-3">
				{label &&
					<label
					className="form-check-label mb-1"
					htmlFor={id}>
						{label} {!required && <small className="text-muted fw-normal">- Optional</small>}
					</label>
				}

				<input
					type="checkbox"
					className={`form-check-input ${css}`}
					id={id}
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					checked={checked}
					required={required}
					disabled={disabled} />

				{error &&
					<small className="form-text text-danger">{error.message}</small>
				}				
			</div>
		</Fragment>
	)
}