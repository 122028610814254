import React from 'react'

/**
 * Label
 * @param props
 * @param  {string} props.htmlFor - HTML for
 * @param  {string} props.css - css class name
 */
export function ADLabel ({ htmlFor = "", css = "", ...props}) {
	return (
		<React.Fragment>			
				<label htmlFor={ htmlFor } className={`form-label ${css}`}>{ props.children }</label>
		</React.Fragment>
	)
}